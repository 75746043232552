import {HymnalSlug, OrganizationKey, SongNumber, SongSlug} from './model';

export const musicManifestFilename = 'manifest3.json';
export const ignoreDirectories = ['.git', '.idea'];
export const metadataFilename = 'metadata.json';

export const customMusicDir = 'custom-music';
export const hymnalsDir = 'hymnals';
export const hymnalsPDFDir = 'hymnals';
export const vocalsDir = 'vocals';
export const thisWeekDirName = 'this_week';

const CustomMusicHymnalName = 'cm';

export function getSongSlug({songNumber, hymnalSlug, churchId}: {
  songNumber: SongNumber,
  hymnalSlug?: HymnalSlug,
  churchId?: number
}): SongSlug {
  if (!hymnalSlug && !churchId) {
    throw new Error(`Must specify either hymnalSlug or churchId for song number ${songNumber}`);
  }
  if (hymnalSlug === CustomMusicHymnalName && !churchId) {
    throw new Error(`Must specify churchId for custom song number ${songNumber}`);
  }
  const prefix = hymnalSlug && hymnalSlug !== CustomMusicHymnalName
    ? `hymnal/${hymnalSlug.toLowerCase()}`
    : `church/${churchId}`;
  return `${prefix}/song/${songNumber}`;
}

export function getChurchCustomMusicManifestPath(organizationKey: OrganizationKey): string {
  return `/${customMusicDir}/${organizationKey}/${musicManifestFilename}`;
}