import {useHouseholdSingingPlan} from '../../data/use_household_singing_plan';
import React, {MouseEventHandler, useCallback, useContext, useEffect, useState} from 'react';
import {
  DateString,
  dateStringFromDate,
  DayOfWeek,
  getDateOfDay,
  getDayOfWeekFromDateString
} from '../../../common/date_string';
import {allSongsCompleted, useSingingPlanCompletions} from '../../data/use_singing_plan_completions';
import {usePrevious} from '../../util/use_previous';
import {ContactSupportLink} from '../../shared';
import {
  DailyHouseholdSingingPlan, HouseholdSingingPlanSong,
  HouseholdSingingPlanSongCategory,
  SongSlug,
  WeeklyHouseholdSingingPlan
} from '../../../common/model';
import {useNavigate} from 'react-router-dom';
import {generateHymnUrlFromSlug, getHymnFromSlug} from '../../util/path';
import {Pages} from '../../../common/pages';
import styled from 'styled-components/macro';
import {ReactComponent as BackArrowIcon} from '../../assets/arrow-previous-left-icon.svg';
import {ReactComponent as CheckMarkIcon} from '../../assets/check-mark-icon.svg';
import {ReactComponent as StopwatchIcon} from '../../assets/stopwatch.svg';
import {Tile} from './shared';
import {userVisibleSongNumber} from '../../../common/util';
import {getHymnOfTheMonthTitle} from '../../../common/church';
import {MusicContext} from '../../data/music_context';

export const SingingPlanTile = () => {
  const {singingPlan} = useHouseholdSingingPlan();
  const [currentDate, setCurrentDate] = useState<string>(() => dateStringFromDate(new Date()));
  const dailyPlan = singingPlan?.days.find(({date}) => date === currentDate);
  const [isStreakVisible, setIsStreakVisible] = useState<boolean>(() => allSongsCompleted(dailyPlan));
  const [isReviewTextVisible, setIsReviewTextVisible] = useState<boolean>(true);
  const previousDailyPlan = usePrevious(dailyPlan)

  useEffect(() => {
    // when we first download a singing plan from the server, the daily plan will initially be undefined
    // until the server download completes, at which point we need to transition to the streak page
    // if the plan was completed
    if (dailyPlan && !previousDailyPlan) {
      setIsStreakVisible(allSongsCompleted(dailyPlan));
    }
    // transitioning from not completed to completed - hide review text
    if (previousDailyPlan && dailyPlan && !previousDailyPlan.completed && dailyPlan.completed) {
      setIsReviewTextVisible(false);
    }
  }, [dailyPlan, previousDailyPlan]);

  const navigateToDate = useCallback((date: DateString) => {
    setCurrentDate(date);
    setIsStreakVisible(false);
    setIsReviewTextVisible(true);
  }, []);

  const onBackFromDay = useCallback(() => {
    setIsStreakVisible(true);
  }, []);

  if (new Date().getDay() === DayOfWeek.Sunday) {
    return null;
  }

  if (isStreakVisible) {
    return (
      <HouseholdSingingPlanStreak
        navigateToDate={navigateToDate}
        singingPlan={singingPlan}
        isReviewTextVisible={isReviewTextVisible}
      />
    );
  }

  if (!dailyPlan) {
    return (
      <Tile>
        <NoSingingPlan>
          Check back later to see a singing plan for your household or <ContactSupportLink/> if you still don't have a
          singing plan.
        </NoSingingPlan>
      </Tile>
    );
  }

  return (
    <DailySongList
      dailyPlan={dailyPlan}
      currentDate={currentDate}
      onBack={onBackFromDay}
    />
  );
};

const DailySongList = ({dailyPlan, currentDate, onBack}: {
  dailyPlan: DailyHouseholdSingingPlan;
  currentDate: DateString;
  onBack: () => void;
}) => {
  const navigate = useNavigate();
  const {hymnals, customMusic} = useContext(MusicContext);
  const {isSongCompleted, markSongCompleted} = useSingingPlanCompletions();

  const onCompleteSong = useCallback((songSlug: SongSlug): () => void => () => {
    markSongCompleted(currentDate, songSlug);
    if (allSongsCompleted(dailyPlan)) {
      onBack();
    }
  }, [currentDate, dailyPlan, markSongCompleted, onBack]);

  const onOpenSong = useCallback((songSlug: SongSlug): () => void => () => {
    if (!hymnals || !customMusic) {
      return;
    }
    const hymnUrl = generateHymnUrlFromSlug({
      songSlug,
      hymnals,
      customMusic,
      page: Pages.Library,
      suppressSongIssue: true,
    });
    if (!hymnUrl) {
      console.log(`Failed to resolve song slug to hymnal URL: ${songSlug}`);
      return;
    }
    console.log(`navigating to hymn URL: ${hymnUrl}`);
    navigate(hymnUrl);
  }, [customMusic, hymnals, navigate]);

  const translateSingingPlanCategory = useCallback((song: HouseholdSingingPlanSong) => {
    const {category} = song;
    if (category !== HouseholdSingingPlanSongCategory.HymnOfTheMonth) {
      return category;
    }
    const hymn = getHymnFromSlug({songSlug: song.slug, hymnals, customMusic});
    if (!hymn) {
      return category;
    }
    return getHymnOfTheMonthTitle([hymn]);
  }, [customMusic, hymnals]);

  const title = currentDate === dateStringFromDate(new Date()) ? 'Today' : getDayOfWeekFromDateString(currentDate);
  const isBackButtonVisible = currentDate !== dateStringFromDate(new Date()) || allSongsCompleted(dailyPlan);
  const hoursLeft = !isBackButtonVisible && Math.round(24 - new Date().getHours());
  return (
    <Tile>
      <TileHeader key='tile-header'>
        <HeadingGroup>
          {isBackButtonVisible && <BackButton onClick={onBack}/>}
          <TileHeadingText>{title}</TileHeadingText>
        </HeadingGroup>
        {hoursLeft && (
          <TimeLeftWrapper>
            <Stopwatch/>
            <TimeLeft>{hoursLeft} HOURS</TimeLeft>
          </TimeLeftWrapper>
        )}
      </TileHeader>
      <SongList key='song-list'>
        {
          dailyPlan.songs
            .map(song => ({
              ...song,
              category: translateSingingPlanCategory(song),
              ...getHymnFromSlug({songSlug: song.slug, hymnals, customMusic}),
            })).map(song => ({
              ...song,
              number: typeof song.number === 'number' ? userVisibleSongNumber(Number(song.number)) : song.number,
            })).map(song =>
            <Song key={song.slug}>
              <CheckMarkButton
                checked={isSongCompleted(currentDate, song.slug)}
                onClick={onCompleteSong(song.slug)}
              />
              <SongInfo onClick={onOpenSong(song.slug)}>
                <Category>{song.category}</Category>
                <Divider/>
                <SongName>
                  {song.number ? `${song.number} - ` : ''}{song.title}{song.psalm ? ` - ${song.psalm}` : ''}
                </SongName>
              </SongInfo>
            </Song>
          )
        }
      </SongList>
    </Tile>
  );
}

const HouseholdSingingPlanStreak = ({navigateToDate, singingPlan, isReviewTextVisible}: {
  navigateToDate: (date: DateString) => void;
  singingPlan: WeeklyHouseholdSingingPlan | undefined;
  isReviewTextVisible: boolean;
}) => {
  const message = isReviewTextVisible ?
    <span>Click on a day to review.<br/>Come back tomorrow to<br/>continue your streak!</span> :
    <span>Practicing daily increases<br/>your confidence, missing<br/>a day resets the streak!</span>;
  const streakInDays = getStreakInDays(singingPlan);
  return (
    <Tile>
      <StreakStack>
        <Flame src='/images/flame.gif'/>
        <StreakDays>{streakInDays} day streak</StreakDays>
        <DaysOfWeek navigateToDate={navigateToDate} singingPlan={singingPlan}/>
        <Divider/>
        <StreakMessage>{message}</StreakMessage>
      </StreakStack>
    </Tile>
  );
}

function getStreakInDays(singingPlan: WeeklyHouseholdSingingPlan | undefined): number {
  if (!singingPlan) {
    return 0;
  }
  let streakInDays = 0;
  for (const day of [...singingPlan.days].reverse()) {
    if (!day.completed) {
      break;
    }
    streakInDays += 1;
  }
  if (singingPlan.priorStreakInDays &&  streakInDays === singingPlan.days.length) {
    streakInDays += singingPlan.priorStreakInDays;
  }
  return streakInDays;
}

const BackButton = styled(BackArrowIcon)`
  width: 12px;
  cursor: pointer;
  fill: var(--color-text);
`;

const StreakStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  gap: 15px;
`;

const Flame = styled.img`
  margin-left: -10px;
  height: 150px; // 200px better on larger screens...
`;

const StreakDays = styled.div`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: 2.5em;
  color: var(--color-text-singing-plan-callout);
`;

const DaysOfWeek = ({navigateToDate, singingPlan}: {
  navigateToDate: (date: DateString) => void;
  singingPlan: WeeklyHouseholdSingingPlan | undefined;
}) => {
  const getDayProperties = useCallback((dayIndex: number) => {
    const date = getDateOfDay(dayIndex, {treatSundayAsLast: true});
    const completed = Boolean(singingPlan?.days.find(day => day.date === date)?.completed);
    const isClickable =
      dayIndex <= new Date().getDay() && singingPlan &&
      singingPlan.days.map(day => day.date).includes(date);
    return {completed, date, isClickable};
  }, [singingPlan]);

  const days = [
    {abbreviation: 'Mo', ...getDayProperties(1)},
    {abbreviation: 'Tu', ...getDayProperties(2)},
    {abbreviation: 'We', ...getDayProperties(3)},
    {abbreviation: 'Th', ...getDayProperties(4)},
    {abbreviation: 'Fr', ...getDayProperties(5)},
    {abbreviation: 'Sa', ...getDayProperties(6)},
  ];

  const onDayClick = useCallback((dateString: DateString) => {
    return () => navigateToDate(dateString);
  }, [navigateToDate]);

  return (
    <DayList>
      {
        days.map(({abbreviation, completed, date, isClickable}) => (
          <Day key={abbreviation} $completed={completed} onClick={isClickable ? onDayClick(date) : undefined}>
            <DayAbbreviation $completed={completed}>{abbreviation}</DayAbbreviation>
            <CheckMarkButton
              checked={completed}
              isPurple={true}
            />
          </Day>
        ))
      }
    </DayList>
  );
}

const DayList = styled.div`
  display: flex;
  gap: min(max(3.5vw, 10px), 20px);
`;

const Day = styled.div<{$completed: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${props => props.onClick ? 'pointer' : 'revert'};
`;

const DayAbbreviation = styled.div<{$completed: boolean}>`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: 1.5em;
  color: ${props => props.$completed ?
  'var(--color-checkmark-song-purple)' :
  'var(--color-text-lighter)'};
`;

const StreakMessage = styled.div`
  font-family: Jost-SemiBold, "Arial", sans-serif;
  font-size: 1.4em;
  color: var(--color-text-darker);
  text-align: center;
  line-height: 1.3em;
  margin-top: -10px;
`;

const TileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Jost-SemiBold, Arial, sans-serif;
`;

const HeadingGroup = styled.div`
  display: flex;
  gap: 15px;
`;

const TileHeadingText = styled.div`
  font-size: 1.6rem;
  line-height: 1.6rem;
`;

const TimeLeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const Stopwatch = styled(StopwatchIcon)`
  width: 20px;
`;

const TimeLeft = styled.div`
  color: var(--color-text-singing-plan-callout);
  text-wrap: nowrap;
`;

const SongList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Song = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const CheckMarkButton = ({checked, onClick, isPurple}: {
  checked: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  isPurple?: boolean
}) => {
  return (
    <CheckMarkButtonWrapper $checked={checked} onClick={onClick} $isPurple={isPurple} $disabled={!onClick}>
      <CheckMark>
        <CheckMarkIcon/>
      </CheckMark>
    </CheckMarkButtonWrapper>
  );
}

const CheckMarkButtonWrapper = styled.button<{
  $checked: boolean,
  $isPurple?: boolean,
  $disabled?: boolean,
}>`
  border: none;
  border-radius: 50px;
  padding: 6px 6px;
  box-shadow: ${props =>
  props.$checked ? '2px 2px 2px hsla(0, 0%, 0%, 0.15), 0 1px 2px hsla(0, 0%, 0%, 0.15)' : 'none'};
  background-color: ${props =>
  props.$checked ?
    (`var(${props.$isPurple ? '--color-checkmark-song-purple' : '--color-checkmark-song'})`) :
    'var(--color-background-button-disabled)'};
  width: fit-content;
  height: fit-content;
  cursor: ${props => props.$disabled ? 'inherit' : 'pointer'};
`;

const CheckMark = styled.div`
  width: 16px;
  height: 16px;
  padding-top: 2px;
  fill: var(--color-background);
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Category = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.4rem;
  color: var(--color-text-darker);
`;

const Divider = styled.hr`
  display: block;
  height: 1px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin-bottom: 4px;
  padding: 0;
`;

const SongName = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 0.9rem;
`;

const NoSingingPlan = styled.div`
`;
