import React, {useCallback, useEffect, useState} from 'react';
import {Message, navigateToOnboardingPage} from './common';
import {Frame} from './frame';
import {Button} from './button';
import {OnboardingPages} from '../../../common/pages';
import {LocalStorageKey, localStorageSet} from '../../data/client_local_storage';
import {nextSundaysDate, tomorrowsDate} from '../../util/stopwatch';
import {useHousehold} from '../../data/use_household';
import {useLocalStorage} from '../../data/use_local_storage';
import {useNavigate} from 'react-router-dom';
import {IOSAppVersionWithPushNotifications, isInsideAppleAppStoreApp} from '../../util/billing';
import styled from 'styled-components/macro';
import {useAllowInAppNotifications} from '../../util/use_allow_in_app_notifications';
import {millisecondsPerDay} from '../../../common/date_string';

// TODO(hewitt): Display on help page (red dot)

const MinDaysBetweenNotifications = 3;

export function useInAppAnnouncementActive(): boolean {
  const {household} = useHousehold();
  const [iOSAppVersion] = useLocalStorage(LocalStorageKey.IOSAppVersion);
  return Boolean(
    household &&
    !household.surveyResponses &&
    (!isInsideAppleAppStoreApp() || (iOSAppVersion !== undefined && iOSAppVersion >= IOSAppVersionWithPushNotifications))
  );
}

export function useDisplayInAppOnboardingNotification() {
  const isActive = useInAppAnnouncementActive();
  const [lastDisplayDate] = useLocalStorage(LocalStorageKey.InAppNotificationLastDisplayDate);
  const navigate = useNavigate();
  const allowInAppNotifications = useAllowInAppNotifications();

  // trigger invalidation when interval between notifications is exceeded
  // TODO(hewitt): put this logic & the Sunday logic below into a general purpose React time threshold hook
  const [intervalBetweenNotificationsExceeded, setIntervalBetweenNotificationsExceeded] = useState(false);
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const daysSinceLastDisplayDate = !lastDisplayDate
      ? MinDaysBetweenNotifications // @ts-ignore -> TypeScript doesn't like subtracting dates for some reason...
      : (new Date() - new Date(lastDisplayDate)) / millisecondsPerDay;
    const intervalExceeded = daysSinceLastDisplayDate >= MinDaysBetweenNotifications;
    if (intervalExceeded !== intervalBetweenNotificationsExceeded) {
      setIntervalBetweenNotificationsExceeded(intervalExceeded);
    }
    if (intervalExceeded) {
      return;
    }
    const timeout = (MinDaysBetweenNotifications - daysSinceLastDisplayDate) * millisecondsPerDay;
    const timer = setTimeout(() => setIntervalBetweenNotificationsExceeded(true), timeout);
    return () => clearTimeout(timer);
  }, [isActive, lastDisplayDate, intervalBetweenNotificationsExceeded, setIntervalBetweenNotificationsExceeded]);

  // trigger invalidation on transition from Sunday to not Sunday and vice versa
  const Sunday = 0;
  const [isSunday, setIsSunday] = useState(() => new Date().getDay() === Sunday);
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const now = new Date().getTime();
    const timeUntilTomorrow = Math.max(0, tomorrowsDate().getTime() - now);
    const timeUntilNextSunday = nextSundaysDate().getTime() - now;
    const timer = setTimeout(() => setIsSunday(!isSunday), isSunday ? timeUntilTomorrow : timeUntilNextSunday);
    return () => clearTimeout(timer);
  }, [isActive, isSunday, setIsSunday]);

  useEffect(() => {
    if (allowInAppNotifications && isActive && !isSunday && intervalBetweenNotificationsExceeded) {
      navigateToOnboardingPage(navigate, OnboardingPages.InAppAnnouncement);
    }
  }, [allowInAppNotifications, isActive, isSunday, intervalBetweenNotificationsExceeded, navigate]);
}

interface Props {
  embed?: boolean;
}

export const InAppOnboardingAnnouncement = ({embed}: Props) => {
  const navigate = useNavigate();
  const onContinue = useCallback(() => {
    navigateToOnboardingPage(navigate, OnboardingPages.SurveyDiscovery);
  }, [navigate]);
  const onMaybeLater = useCallback(() => {
    // this will naturally dismiss the current screen
    localStorageSet(LocalStorageKey.InAppNotificationLastDisplayDate, new Date().toString());
    // wait for new setting to take effect before navigating (setTimeout)
    // Note that navigate(-1) would be better, but doesn't work if this is the first page.
    // Some posts suggest checking navigate.key to see if there is a previous page, but that doesn't seem to work.
    setTimeout(() => navigate('/'));
  }, [navigate]);

  return (
    <Frame
      onboardingPage={OnboardingPages.InAppAnnouncement}
      mainContent={(
        <Announcement $widthInChars={24}>
          Thank you for using our app!<br/>
          We have a few questions for you to improve your experience.
        </Announcement>
      )}
      footerContent={(
        <>
          <Button onClick={onContinue}>SOUNDS GREAT!</Button>
          {!embed && <Button onClick={onMaybeLater}>MAYBE LATER</Button>}
        </>
      )}
      onEnterKeypress={onContinue}
      suppressBackButton={true}
      embed={embed}
    />
  );
}

const Announcement = styled(Message)`
  text-align: center;
  font-size: 1.1em;
  padding-top: 15px;
  padding-bottom: 25px;
`;
