import styled from 'styled-components/macro';
import React, {useCallback} from 'react';
import {useLogMessages} from '../util/logging';

export const FailurePage = () => {
  const onRestart = useCallback(() => {
    window.location.href = '/';
    return false;
  }, []);
  const {logMessages} = useLogMessages();

  return (
    <FailureWrapper>
      <FailureContent>
        <span>
          App failed to load.<br/>
          A crash report has been sent to our engineers.<br/>
          <a href="mailto:support@crescendosw.com" style={{color: "lightblue", textDecoration: "underline"}}>Click
            here</a> to reach out to support if you have questions or concerns.
        </span>
      </FailureContent>
      <RestartButton onClick={onRestart}>Restart Application</RestartButton>
      <ScrollContainer>
        {
          logMessages.map(({index, message}) => {
            return(
              <Message key={index}>{message}</Message>
            )
          })
        }
      </ScrollContainer>
    </FailureWrapper>
  );
};

const FailureWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#5200BB, black);
`;

const FailureContent = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  margin: auto;
  font-size: 1.2rem;
  color: white;
  padding: 5% 5%;
`;

const RestartButton = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3rem;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: white;
  color: black;
  cursor: pointer;
`;

const ScrollContainer = styled.div`
  height: 50vh;
  width: 100vw;
  margin-top: 10%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: start;
`;

const Message = styled.div`
  text-align: left;
  color: var(--color-background);
`
