import styled from 'styled-components/macro';
import {ReactComponent as BackArrowIcon} from '../../../assets/arrow-previous-left-icon.svg';
import React from 'react';

export const MaxSongListRowCount = 15;
export const SongRowWidth = 'min(calc(100vw - 4px), 35ch)';
export const SongRowHeight = '4rem';
export const ColumnFullWidth = -1;
export const LiturgyYearHeight = '59px'; // for sticky positioning (yuck)

interface Props {
  onClick: () => void;
}

export const BackButton = ({onClick}: Props) => {
  return (
    <BackButtonWrapper onClick={onClick}>
      <BackArrowIconWrapper/>
    </BackButtonWrapper>
  );
}

export const BackButtonWidth = '35px';
export const BackButtonHeight = '55px';

// takes up space in the flow where the back button would have resided
export const BackButtonPlaceholder = styled.div`
  width: ${BackButtonWidth};
  height: ${BackButtonHeight};
`;

const BackButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 11px;
  cursor: pointer;
  background-color: var(--color-background);
`

const BackArrowIconWrapper = styled(BackArrowIcon)`
  width: 15px;
  fill: var(--color-text);
`;
