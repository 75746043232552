import {SettingsDisplay} from './shared';
import {ForwardArrow} from './household_settings_page';
import {getSongIntroName, localStorageGet, LocalStorageKey, localStorageSet} from '../../data/client_local_storage';
import React from 'react';
import {useLocalStorage} from '../../data/use_local_storage';
import './shared.css';
import '../settings_page.css'

interface Props {
  setDisplay: (Display: SettingsDisplay) => void;
}

export const SongOptions = ({setDisplay}: Props) => {
  const [verseCount] = useLocalStorage(LocalStorageKey.VerseCount);
  const [songIntroduction] = useLocalStorage(LocalStorageKey.SongIntroduction);

  function handlePdfAutoDisplayToggle(e: any) {
    let isChecked = e.target.checked;
    localStorageSet(LocalStorageKey.PdfAutoDisplay, isChecked)
  }

  return (
    <table className="settings">
      <tbody>
      <tr className="setting arrow" onClick={() => setDisplay(SettingsDisplay.VerseCount)}>
        <td className="settingName">Verse Count</td>
        <td className="currentValue">{verseCount ? verseCount : 'All'}</td>
        <td className='settingAction'>
          <ForwardArrow/>
        </td>
      </tr>
      <tr className="setting arrow" onClick={() => setDisplay(SettingsDisplay.SongIntroduction)}>
        <td className="settingName">Song Introduction</td>
        <td className="currentValue">{getSongIntroName(songIntroduction)}</td>
        <td className='settingAction'>
          <ForwardArrow/>
        </td>
      </tr>
      <tr className="setting">
        <td className="settingName">Show PDF on Play</td>
        <td className="settingAction" colSpan={2}>
          <div className="toggle">
            <input type="checkbox" onChange={e => handlePdfAutoDisplayToggle(e)}
                   defaultChecked={localStorageGet(LocalStorageKey.PdfAutoDisplay)}/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  );
}