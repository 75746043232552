// 'hh:mm:ss' -> '01:30:00' = 1:30am, '17:15:00' = 5:15pm
export type TimeString = string;

// converts format '1899-12-30T18:30:00.000-08:00' to '18:30:00'
export function getTimeFromDateTime(time: string | undefined): TimeString | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/T?(?<hour>\d\d):(?<minute>\d\d)(:(?<second>\d\d))?/);
  if (!match || !match.groups) {
    return;
  }
  const {hour, minute, second} = match.groups;
  return `${hour}:${minute}:${second}`;
}

// converts '17:00:00' to '5pm'
export function getShortTime(
  time: TimeString | undefined,
  {alwaysIncludeMinutes, includeSpace}: {alwaysIncludeMinutes?: boolean, includeSpace?: boolean} = {}
): string | undefined {
  if (!time) {
    return;
  }
  const match = time.match(/^(?<hour>\d?\d):(?<minute>\d\d)(:(?<second>\d\d))?/);
  if (!match || !match.groups) {
    return;
  }
  const {hour: hourString, minute} = match.groups;
  const militaryHour = Number(hourString);
  const hour = militaryHour === 0 ? 12 : (militaryHour > 12 ? militaryHour - 12 : militaryHour);
  const ampm = militaryHour < 12 || militaryHour === 24 ? 'am' : 'pm';
  const space = includeSpace ? ' ' : '';
  if (!alwaysIncludeMinutes && minute === '00') {
    return `${hour}${space}${ampm}`;
  }
  return `${hour}:${minute}${space}${ampm}`;
}

export function parseInputAsTimeString(input?: string): TimeString | undefined {
  if (!input) {
    return undefined;
  }
  // must at least specify the hour with numeric digits (no typing "one pm")
  const hourString = input.match(/(?<hour>\d+)/)?.groups?.hour;
  if (!hourString) {
    return;
  }
  let hour = Number(hourString);
  const minuteString = input.match(/:(?<minute>\d+)/)?.groups?.minute;
  const minute = minuteString ? (Number(minuteString) % 60) : 0;
  const secondString = input.match(/:\d+:(?<second>\d+)/)?.groups?.second;
  const second = secondString ? (Number(secondString) % 60) : 0;
  let ampm = input.toLowerCase().match(/\d\s*(?<ampm>[ap])/)?.groups?.ampm;
  if (!ampm) {
    // no am/pm -> assume 9am - 8pm
    ampm = hour >= 9 && hour <= 11 ? 'a' : 'p';
  }
  // military time
  if (hour < 12 && ampm === 'p') {
    hour += 12;
  }
  const twoDigits = (number: number) => number.toString().padStart(2, '0');return `${twoDigits(hour)}:${twoDigits(minute)}:${twoDigits(second)}`;
}
