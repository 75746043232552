import {ReactComponent as BackArrowIcon} from '../assets/arrow-previous-left-icon.svg';
import React, {useCallback, useEffect, useState} from 'react';
import {RequestSongPage, requestSongPageDialog} from './request_song_page';
import {ensureExists} from '../../common/util';
import {Document, Page} from 'react-pdf';
import {getPdfUrlForHymn} from '../util/path';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from 'styled-components/macro';
import {Hymn, HymnIssue} from '../../common/model';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {Spinner} from '../util/spinner';
import {FloatingBackButton} from '../shared';

interface Props {
  hymn: Hymn;
  width: number;
  height: string; // values like '75vh'
  headerHeight?: string; // values like '55px'
  issue?: HymnIssue;
  onClose?: () => void;
  onFinishedLoading?: () => void;
  isPDFVisible?: boolean;
}

export function PdfViewerPage({
  hymn,
  width,
  height,
  headerHeight,
  issue,
  onClose,
  onFinishedLoading,
  isPDFVisible
}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>();
  const pdfUrl = getPdfUrlForHymn(hymn);
  const {isInternalUser} = useUserAttributes();
  const [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf] =
    useLocalStorage(LocalStorageKey.RequestSongDisplayedForPDF);

  useEffect(() => {
    void (async () => {
      // only show modal if .mid file is missing and .pdf file loaded successfully
      if (issue !== HymnIssue.Missing || !numPages || requestSongDisplayedForPdf[hymn.hymnal]?.[hymn.number]) {
        return;
      }
      setRequestSongDisplayedForPdf({
        ...requestSongDisplayedForPdf, [hymn.hymnal]: {
          ...requestSongDisplayedForPdf[hymn.hymnal], [hymn.number]: true
        }
      });
      await requestSongPageDialog({
        confirmation: {
          issue,
          hymnalName: ensureExists(hymn.hymnal),
          songNumber: hymn.number,
        }
      });
    })();
  }, [requestSongDisplayedForPdf, setRequestSongDisplayedForPdf, hymn.hymnal, hymn.number, issue, numPages]);

  const onLoadSuccess = useCallback(({numPages}: { numPages: number }) => {
    setNumPages(numPages);
    onFinishedLoading?.();
  }, [onFinishedLoading]);

  const onLoadError = useCallback((error: Error) => {
    setError(error);
    console.log(error.message);
    onFinishedLoading?.();
  }, [onFinishedLoading]);

  function renderLoadingPage() {
    return (
      <LoadingWrapper>
        <LoadingMessage>
          Loading sheet music...
        </LoadingMessage>
        <Spinner/>
      </LoadingWrapper>
    );
  }

  const isRequestPageVisible = (hymn.issue === HymnIssue.Text && !isInternalUser) || error !== undefined;

  const pdfDoc = (
    <PdfDoc key="pdf" height={height} headerHeight={headerHeight}>
      {
        isRequestPageVisible &&
        <RequestSongPage
          issue={error !== undefined ? HymnIssue.Missing : HymnIssue.Text}
          hymnalName={ensureExists(hymn.hymnal)}
          songNumber={hymn.number}/>
      }
      <div style={{...((numPages === undefined || error || isRequestPageVisible) && {visibility: 'hidden'})}}>
        <Document
          file={pdfUrl}
          options={{workerSrc: "/pdf.worker.js"}}
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
        >
          {
            Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} key={page} width={width}/>)
          }
        </Document>
      </div>
    </PdfDoc>
  );

  return isPDFVisible ? (
    <>
      <FloatingBackButton onClick={onClose}><BackArrowIcon/></FloatingBackButton>
      {pdfDoc}
      {!isRequestPageVisible && error === undefined && numPages === undefined && renderLoadingPage()}
    </>
  ) : pdfDoc;
}
export const PdfDoc = styled.div<{height: string, headerHeight?: string}>`
  ${props =>
    props.headerHeight ? `
      margin-top: -${props.headerHeight};
    ` : ''
  }
  height: ${props => props.height};
  overflow-y: scroll;
  overflow-x: clip;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingMessage = styled.div`
  font-size: 1.3em;
  width: 20ch;
  padding-bottom: 1.5em;
`;
