import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as BlankProfilePicture} from "../assets/blank-profile-picture.svg";
import {LocalStorageKey, Guest} from "../data/client_local_storage";
import {useLocalStorage} from "../data/use_local_storage";
import {AppleLogin, showGoogleButton} from "./apple_login";
import GoogleLogin, {getGooglePicture, isGoogleCredentials} from "./google_login";
import './user_info.css'
import {isInsideGooglePlayApp, migrateUserChurchToHouseholdChurch} from '../util/billing';
import styled from 'styled-components/macro';
import {useNavigate} from 'react-router-dom';

interface Props {
  visible: boolean;
  prompt?: string;
}

export function UserInfo(props: Props) {
  const [user, setUser] = useLocalStorage(LocalStorageKey.User);
  const [credentials] = useLocalStorage(LocalStorageKey.Credentials);
  const [picture, setPicture] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // Apple does not provide a photo
    setPicture(credentials && isGoogleCredentials(credentials) ? getGooglePicture(credentials) as any : undefined);
  }, [credentials]);

  useEffect(() => {
    void migrateUserChurchToHouseholdChurch();
  }, [user]);

  const handleSignOut = useCallback(() => {
    setUser(Guest);
    navigate('/');
  }, [navigate, setUser]);

  const profilePicture =
    picture ?
      (<img src={picture} alt='Avatar' id="profilePicture"/>) :
      (<BlankProfilePicture id="profilePicture"/>);

  const userName = props.prompt ?? (
    user && user !== Guest && user.name ? user.name : "Not Signed In"
  );

  const {hostname} = window.location;
  const isLocalHost = hostname.includes('localhost');

  const buttons = () => {

    const appleButton = () => {

      // occasionally on localhost, AppleID is not defined
      // (see https://github.com/patelmayankce/react-apple-login/issues/41)
      // seems to only happen on when the AppleLogin is rendered but not actually visible
      // Apple login doesn't work for local host anyway, so hiding it

      if (!props.visible || isLocalHost) {
        return (
          <div>Hiding Apple Login</div>
        )
      }

      if (!isInsideGooglePlayApp()) {
        return (
          <AppleLogin location={"settings"}/>
        )
      }
    }

    const googleButton = () => {
      if (showGoogleButton()) {
        return (
          <GoogleLogin hidden={!props.visible} location={"settings"}/>
        )
      }
    }

    // if signed in, show sign out button
    if (credentials) {
      return (
        <div id="lowerDiv">
          <button onClick={handleSignOut} id="signOutButton">Sign Out</button>
        </div>
      )
    }

    return (
      <ButtonWrapper>
        {googleButton()}
        {appleButton()}
      </ButtonWrapper>
    )
  }

  const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
  `;

  return (
    <div id="loginDiv">
      <div id="loginInfo">
        {profilePicture}
        <UserName>{userName}</UserName>
      </div>
      {buttons()}
    </div>
  )
}

const UserName = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  padding-top: 10px;
  white-space: pre-wrap;
`;