import {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {
  ClientChurch,
  CustomMusicManifest,
  getOrgId,
  Hymn,
  HymnalManifest,
  HymnalSlug,
  HymnalsManifest,
  HymnalWithHymns
} from '../../common/model';
import {favorites} from './favorites';
import {customMusicDir, getChurchCustomMusicManifestPath, getSongSlug, musicManifestFilename} from '../../common/paths';
import * as server_api from '../../common/server_api';
import {userVisibleSongNumber} from '../../common/util';
import {useLocalStorage} from './use_local_storage';
import {LocalStorageKey} from './client_local_storage';
import {useChurch} from './use_church';
import {getHymnFromSlug} from '../util/path';

export const PrimaryChurchHymnalId = Number.MAX_SAFE_INTEGER;
export const PrimaryChurchHymnalSlug = 'PRIMARY';
export const PrimaryChurchHymnalName = 'Our Hymnal';

export const MusicContext = createContext<{
  hymnals: HymnalWithHymns[];
  customMusic: CustomMusic;
  getSongSlugForHymn: GetSongSlugForHymn; // TODO(hewitt): seems bogus that we are surfacing this here
}>({hymnals: [], customMusic: {}, getSongSlugForHymn: () => undefined});

export type CustomMusic = {[orgId: string]: Hymn[]};
type GetSongSlugForHymn = (hymn: {number: number, hymnal: string, basePath?: string}) => string | undefined;

// NOTES:
// - Called twice whenever any inputs change (not sure why)
// - Called a minimum of 6x (3 pairs) during boot -> initially, when manifest loads, when hymnals populated
// - Not called in steady state during page transitions

export const MusicProvider = ({children}: {children: ReactNode}) => {
  const customMusic = useCustomMusic();
  const {hymnals, getSongSlugForHymn} = useHymnals(customMusic);
  return (
    <MusicContext.Provider value={{hymnals, customMusic, getSongSlugForHymn}}>
      {children}
    </MusicContext.Provider>
  );
}


/********************************** hymnals **********************************/

function useHymnals(customMusic: CustomMusic): {hymnals: HymnalWithHymns[], getSongSlugForHymn: GetSongSlugForHymn} {
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const hymnalsManifest = useHymnalsManifest();
  const getSongSlugForHymn = useGetSongSlugForHymn(hymnalsManifest);
  const {church} = useChurch();
  const [weeklySongList] = useLocalStorage(LocalStorageKey.WeeklySongList); // for church hymnal invalidation

  useEffect(() => {
    if (hymnalsManifest) {
      (async () => {
        try {
          const populatedHymnals = await getHymnalsFromHymnalsManifest(hymnalsManifest);
          for (const hymnal of populatedHymnals) {
            favorites.registerHymnal(hymnal);
          }
          if (church) {
            populatedHymnals.unshift(await getChurchHymnal(church, populatedHymnals, customMusic));
          }
          setHymnals(populatedHymnals);
        } catch (error: any) {
          // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
          console.error(`Failed to fetch hymnals: ${error.message}`);
        }
      })();
    }
  }, [church, getSongSlugForHymn, hymnalsManifest, customMusic, weeklySongList]);

  return {hymnals, getSongSlugForHymn};
}

async function getChurchHymnal(
  church: ClientChurch,
  hymnals: HymnalWithHymns[],
  customMusic: CustomMusic,
) {
  const churchHymnal = await server_api.getChurchHymnal(church.id);
  const primaryHymnal = church?.primaryHymnalId &&
    hymnals.find(hymnal => hymnal.id === church.primaryHymnalId);
  let hymns = churchHymnal.songs
    .map(song => getHymnFromSlug({songSlug: song.slug, hymnals, customMusic}))
    .filter(hymn => !!hymn) as Hymn[];
  if (primaryHymnal) {
    const hymnsNotInPrimaryHymnal = hymns.filter(hymn => hymn.hymnal !== primaryHymnal.name);
    hymns = [
      ...hymnsNotInPrimaryHymnal,
      ...primaryHymnal.hymns.map(hymn => ({...hymn})), // need a new object to distinguish from original hymn
    ];
  }

  // sort custom songs to top, ordered alphabetically, non-custom songs to bottom, ordered by song number
  hymns.sort((lhs, rhs) => {
    if (lhs.basePath) {
      if (rhs.basePath) {
        return lhs.title.localeCompare(rhs.title);
      }
      return 0 - rhs.number;
    }
    if (rhs.basePath) {
      return lhs.number /*- 0*/;
    }
    return lhs.number - rhs.number;
  });
  return {
    id: PrimaryChurchHymnalId,
    name: PrimaryChurchHymnalName,
    isChurchSpecific: true,
    slug: PrimaryChurchHymnalSlug,
    hymns,
  };
}

export async function getHymnalsFromHymnalsManifest(hymnalsManifest: HymnalsManifest) {
  const hymnalNames = Object.keys(hymnalsManifest);
  const hymnalManifests: HymnalManifest[] = await Promise.all(
    hymnalNames.map(name => server_api.getHymnalManifest(name))
  );
  const populatedHymnals: HymnalWithHymns[] = hymnalManifests.map((hymnalManifest, index) => {
    const hasSuffix = (number: string) => Boolean(number.match(/\d+\.\d+/));
    const songNumbers = countSongNumbers(hymnalManifest);
    const [name, entry] = Object.entries(hymnalsManifest)[index];
    return {
      name,
      ...entry,
      hymns: getHymnsFromHymnalManifest(name, hymnalManifest,
        // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
        // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
        {filter: (number: string) => hasSuffix(number) || songNumbers[number] <= 2})
    };
  });
  return populatedHymnals;
}

function countSongNumbers(hymnalManifest: HymnalManifest) {
  return Object.keys(hymnalManifest)
    .map(key => key.match(/^\d+/)?.[0])
    .filter(key => key !== undefined)
    .reduce((prev, cur) => {
      // @ts-ignore (TS not yet smart enough to apply the filter)
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {} as {[number: string]: number});
}

export function getHymnsFromHymnalManifest(
  hymnalName: string, hymnalManifest: HymnalManifest, {filter, basePath}: {
    filter?: (number: string) => boolean;
    basePath?: string;
  } = {}
): Hymn[] {
  return Object.entries(hymnalManifest)
    // hide songs without suffixes that match songs with suffixes (e.g. hide 17 if 17.01 and 17.02 exist)
    // leave song in if the .01 is marked "alt." (see CC2020 597 & 597a), in which case the count is 2
    .filter(([number]) => !filter || filter(number))
    .map(([number, data]) => ({...data, number: Number(number), hymnal: hymnalName, ...(basePath && {basePath})}))
    .sort((lhs, rhs) => lhs.number - rhs.number)
}

export function useGetSongSlugForHymn(
  hymnalsManifest: HymnalsManifest | undefined,
): GetSongSlugForHymn {
  const getSongSlugForHymn = useCallback((hymn: {number: number, hymnal: string, basePath?: string}) => {
    const songNumber = userVisibleSongNumber(hymn.number);
    let hymnalSlug: HymnalSlug | undefined;
    let churchId: string | undefined;
    if (hymn.basePath) {
      churchId = hymn.hymnal.match(/^(?<churchId>\d+)/)?.groups?.churchId;
      if (!churchId) {
        console.log(`ERROR: Cannot parse church Id from hymnal "${hymn.hymnal}" for hymn with basePath ${hymn.basePath}`);
        return undefined;
      }
    } else {
      hymnalSlug = hymnalsManifest?.[hymn.hymnal]?.slug;
      if (!hymnalSlug) {
        return undefined;
      }
    }
    return getSongSlug({songNumber, hymnalSlug, churchId: churchId ? Number(churchId) : undefined});
  }, [hymnalsManifest]);
  return getSongSlugForHymn;
}


/***************************** hymnals manifest ******************************/

export function useHymnalsManifest(): HymnalsManifest | undefined {
  const [hymnalsManifest, setHymnalsManifest] = useState<HymnalsManifest | undefined>();
  const [hymnalsHash] = useLocalStorage(LocalStorageKey.HymnalsHash);

  useEffect(() => {
    (async () => {
      try {
        const serverManifest = await server_api.getHymnalsManifest();
        setHymnalsManifest(serverManifest);
      } catch {
        console.error(`Unable to retrieve global hymnals manifest`);
      }
    })();
  }, [hymnalsHash]);

  return hymnalsManifest;
}


/******************************* custom music ********************************/

const customMusicManifestPath = `/${customMusicDir}/${musicManifestFilename}`;

function useCustomMusic(): CustomMusic {
  const [customMusic, setCustomMusic] = useState<CustomMusic>({});
  const [customMusicManifest, setCustomMusicManifest] = useState<CustomMusicManifest | undefined>();
  const [customMusicHash] = useLocalStorage(LocalStorageKey.CustomMusicHash);

  useEffect(() => {
    void (async () => {
      setCustomMusicManifest(await fetch(customMusicManifestPath).then(res => res.json()));
    })();
  }, [customMusicHash]);

  useEffect(() => {
    (async () => {
      try {
        if (!customMusicManifest) {
          return;
        }
        const churchManifests: HymnalManifest[] = await Promise.all(Object.keys(customMusicManifest)
          .map(key => fetch(getChurchCustomMusicManifestPath(key)).then(res => res.json())));
        const hymnLists = Array.from(Object.keys(customMusicManifest).entries())
          .map(([index, key]) => [
            getOrgId(key),
            getHymnsFromHymnalManifest(key, churchManifests[index], {basePath: `/${customMusicDir}`})
          ]);
        setCustomMusic(Object.fromEntries(hymnLists));
      } catch (err: any) {
        // TODO(hewitt): Retry strategy?  Perhaps when we implement React Router data sources.
        console.error(`Failed to download custom music: ${err}`);
      }
    })();
  }, [customMusicManifest]);

  return customMusic;
}
