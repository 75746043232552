import React, {useCallback, useContext, useEffect, useState} from 'react';
import {HymnsList} from './hymns_list';
import '../shared.css';
import {specialCaseSchools, synchronizeWeeklySongLists, useChurch} from '../data/use_church';
import {useLocalStorage} from '../data/use_local_storage';
import {LocalStorageKey} from '../data/client_local_storage';
import {
  Church,
  ClientChurch,
  Household,
  Hymn,
  HymnalWithHymns,
  isAdminForCurrentChurch,
  OrganizationType,
  SchoolTerm,
  SongList,
  SongListDeprecated,
  SongListType
} from '../../common/model';
import {
  dateFromString,
  DateString,
  firstDayOfWeek,
  getWeekNumber,
  thisSundayDate,
  todayDate
} from '../../common/date_string';
import {useHousehold} from '../data/use_household';
import {ensureUnreachable} from '../../common/util';
import {getShortTime, getTimeFromDateTime} from '../../common/time_string';
import {Pages} from '../../common/pages';
import {getHymnFromSongListEntry, getUrlForPage} from '../util/path';
import {shareWithMusicLeader} from './onboarding/common';
import {DemoChurch, synchronizeDemoSongList} from '../data/demo_song_list';
import {stringFromChurch} from '../../common/server_api';
import {Link, useNavigate} from 'react-router-dom';
import {getHymnOfTheMonthTitle, getSundayTitle} from '../../common/church';
import {MusicContext, CustomMusic} from '../data/music_context';
import {useUserAttributes} from '../data/use_user_attributes';

const thisWeekHymnalSlug = 'THIS-WEEK';

export interface Props {
  isDemo?: boolean;
  checkSongLists?: (songLists: Array<{type: SongListType, date: DateString}> | undefined) => void;
  showThisSundayOnly?: boolean;
}

export const OrganizationPage = ({isDemo, checkSongLists, showThisSundayOnly}: Props) => {
  const {church} = useChurch();
  const {household} = useHousehold();
  const [songsForWeek] = useLocalStorage(LocalStorageKey.WeeklySongList);
  const [demoSongs] = useLocalStorage(LocalStorageKey.DemoSongList);
  const {hymnals: allHymnals, customMusic} = useContext(MusicContext);
  const [hymnals, setHymnals] = useState<HymnalWithHymns[]>([]);
  const navigate = useNavigate();
  const {isInternalUser} = useUserAttributes();

  useEffect(() => {
    if (isDemo) {
      void synchronizeDemoSongList();
    } else {
      void synchronizeWeeklySongLists();
    }
  }, [isDemo]);

  useEffect(() => checkSongLists?.(songsForWeek), [checkSongLists, songsForWeek]);

  useEffect(() => {
    let songLists: Array<SongList | SongListDeprecated>;
    if (isDemo) {
      songLists = demoSongs;
    } else if (showThisSundayOnly) {
      songLists = songsForWeek?.filter(songList =>
        songList.type === SongListType.WorshipService && songList.date === thisSundayDate()
      );
    } else {
      songLists = songsForWeek;
    }
    setHymnals(getHymnalsFromSongLists({
      songLists,
      church: isDemo ? undefined : church,
      allHymnals,
      customMusic,
      isDemo,
      household,
      showThisSundayOnly,
      isInternalUser,
    }));
  }, [allHymnals, customMusic, isDemo, demoSongs, showThisSundayOnly, songsForWeek, church, household, isInternalUser]);

  const onShare = useCallback(async () => {
    await shareWithMusicLeader();
  }, []);

  const askYourMusicLeader =
    <button className="inlineLink" onClick={onShare}>
      ask your music leader
    </button>;

  const onEditHymnal = useCallback((hymnal: HymnalWithHymns) => {
    if (hymnal.songListType === SongListType.Event) {
      navigate(getUrlForPage(Pages.EventCalendar));
    } else {
      navigate(getUrlForPage(Pages.LiturgyPlanner));
    }
  }, [navigate]);

  let message: JSX.Element | undefined;
  if (isDemo) {
    message = undefined;
  } else if (!household) {
    // this code path should be impossible (RootPage should have navigated to onboarding demo, passing isDemo=true)
    // displaying URL (window.location.href) as a debugging tool because some customers are still seeing it... :(
    message =
      <div>
        To view songs for your church, {askYourMusicLeader} to add your church to the app or <span
        className="inlineLink" onClick={() => navigate(getUrlForPage(Pages.Settings))}>sign in</span> if your church
        already participates.<br/>
        <br/>
        <p>{window.location.href}</p>
      </div>;
  } else if (!church) {
    message = <div>To use this tab, select a church in household <Link to={getUrlForPage(Pages.Settings)}>settings</Link> or {askYourMusicLeader} to add your church to the app. If you are the music leader, <Link to='https://calendar.app.google/gnnXn7zGBiB1fN469' target="_blank">click here</Link> to talk about getting your church setup.</div>;
  } else if (songsForWeek === undefined) {
    message = <div>Downloading songs for this week...</div>;
  } else if (hymnals.length === 0 && church.type === OrganizationType.Church) {
    message = <div>Your music director has not yet added songs for this Sunday.</div>;
  }
  return (
    <HymnsList
      title={church?.name ?? 'Your Church'}
      weeklySongGroups={hymnals}
      message={message}
      isDemo={isDemo}
      showThisSundayOnly={showThisSundayOnly}
      onEditHymnal={onEditHymnal}
    />
  );
};

function getHymnalsFromSongLists({
  songLists,
  church,
  allHymnals,
  customMusic,
  isDemo,
  household,
  showThisSundayOnly,
  isInternalUser,
}: {
  songLists: Array<SongList | SongListDeprecated>;
  church?: Church;
  allHymnals: HymnalWithHymns[] | undefined;
  customMusic: CustomMusic | undefined;
  isDemo: boolean | undefined;
  household: Household | undefined;
  showThisSundayOnly: boolean | undefined;
  isInternalUser: boolean;
}): HymnalWithHymns[] {
  const filteredSongLists = songLists
    .filter(songList =>
      songList.type !== SongListType.StaticList || (
        songLists.length === 1 &&
        church?.type !== OrganizationType.Church
      ));

  const filterEvents = (songLists: (SongList | SongListDeprecated)[]) => {

    const events = songLists.filter(songList => songList.type === SongListType.Event);

    if (!events.length) {
      return [];
    }

    if (isDemo) {
      const date = new Date();
      const futureEvents = events.filter(songList =>
        dateFromString(songList.date) >= date
      )
      if (!futureEvents.length) {
        return [];
      }
      let nextEvent = futureEvents[0];
      for (const event of futureEvents) {
        if (event.date < nextEvent.date ) {
          nextEvent = event;
        }
      }
      return [nextEvent];
    }

    return events;
  }

  const orderedSongLists = [
    ...filteredSongLists.filter(songList => songList.type === SongListType.HymnOfTheMonth),
    ...filteredSongLists
      .filter(songList =>
        songList.type === SongListType.WorshipService || songList.type === SongListType.WeeklyList)
      .sort((lhs, rhs) => rhs.date.localeCompare(lhs.date)),
    ...filterEvents(filteredSongLists),
  ];

  const hymnals: HymnalWithHymns[] = orderedSongLists
    .map((songList) => {
      const hymns = songList.songs
        .map(song => getHymnFromSongListEntry({song, hymnals: allHymnals, customMusic}))
        .filter(hymn => hymn) as Hymn[];
      return {
        id: 0,
        name: getSongListName(church, songList, hymns, songLists.length),
        hymns,
        slug: thisWeekHymnalSlug,
        songListType: songList.type,
        message: songList.songs.length === 0 ? <div>Your music director has not yet added any songs for this event.</div> : undefined
      };
    });
  if (
    !songLists.find(({date, type}) => date.includes(thisSundayDate()) && type === SongListType.WorshipService) &&
    church?.type === OrganizationType.Church &&
    !specialCaseSchools.includes(church?.name) &&
    // hide empty "This Sunday" for single static song list as well as demo church
    (songLists.length > 1 || hymnals.length === 0 || hymnals[0].name !== '') &&
    stringFromChurch(church) !== DemoChurch
  ) {
    // keep hymn of the month first
    const containsHymnOfTheMonth = songLists.length > 0 && songLists[0].type === SongListType.HymnOfTheMonth;
    const start = containsHymnOfTheMonth ? 1 : 0;
    hymnals.splice(start, 0, {
      id: 0,
      name: getSundayTitle(thisSundayDate()),
      hymns: [],
      message: <div>Your music leader has not yet added any songs for this week.</div>,
      slug: thisWeekHymnalSlug,
    });
  }
  if (
    church?.type !== OrganizationType.School &&
    !songLists.find(list => list.type === SongListType.Event) &&
    (isAdminForCurrentChurch(household) || isInternalUser) &&
    !showThisSundayOnly
  ) {
    hymnals.push({
      id: 0,
      name: 'Event Calendar',
      hymns: [],
      message: <div/>,
      slug: thisWeekHymnalSlug,
      songListType: SongListType.Event,
    });
  }
  return hymnals;
}

function getSongListName(
  church: ClientChurch | undefined,
  songList: SongList | SongListDeprecated,
  hymns: Hymn[],
  songListCount: number,
) {
  const {name, type, date: dateStr, time} = songList;
  switch (type) {
    case SongListType.SongsOfTheTerm:
      return 'Songs of the Term'
    case SongListType.HymnOfTheMonth:
      return getHymnOfTheMonthTitle(hymns);
    case SongListType.WorshipService:
      return getSundayTitle(dateStr);
    case SongListType.WeeklyList:
      return getWeeklyListName(church, dateStr);
    case SongListType.Event:
      const date = dateFromString(dateStr);
      const datePart = date ? ` - ${getDateAsShortString(date)}` : '';
      const shortTime = getShortTime(getTimeFromDateTime(time));
      const timePart = shortTime ? ` @ ${shortTime}` : '';
      return `${name ?? 'Event'}${datePart}${timePart}`;
    case SongListType.StaticList:
      // If there is only one static song list, it gets an empty title (flat list of songs)
      return songListCount === 1 ? '' : 'Songs';
    default:
      ensureUnreachable(type);
  }
}

function getWeeklyListName(church: ClientChurch | undefined, date: DateString) {
  const currentTerm = getCurrentTerm(church?.schoolTerms);
  const name = currentTerm?.name;
  let week: number;
  if (!currentTerm) {
    week = getWeekNumber(date);
  } else {
    const firstSundayOfTerm = firstDayOfWeek(currentTerm.startDate);
    week = getWeekNumber(date) - getWeekNumber(firstSundayOfTerm) + 1;
  }
  return `${name ? name + ' - ' : ''}Week ${week}`
}

function getCurrentTerm(schoolTerms?: SchoolTerm[]): SchoolTerm | undefined {
  if (schoolTerms?.length) {
    const today = todayDate();
    let result = schoolTerms[0];
    for (const term of schoolTerms) {
      if (term.startDate <= today && term.startDate > result.startDate) {
        result = term;
      }
    }
    return result;
  }
}

function getDateAsShortString(date: Date) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${months[date.getMonth()]} ${date.getDate()}`;
}
