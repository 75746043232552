import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {SongList} from '../../common/model';

const DBName = 'sing-your-part';
const TestDBName = 'sing-your-part-test';
const SongListUpdateObjectStore = 'song-list-updates';

type TimeStamp = number;

export interface DeletableSongList extends SongList {
  isDeleted?: boolean;
}

interface UpdateSongListDB extends DBSchema {
  [SongListUpdateObjectStore]: {
    key: TimeStamp;
    value: DeletableSongList;
  };
}

async function openSingYourPartDB({test}: {test?: boolean} = {}): Promise<IDBPDatabase<UpdateSongListDB>> {
  // this is supposed to be cheap enough that we can do it on every operation rather than persisting the open session
  // https://stackoverflow.com/questions/40593260/should-i-open-an-idbdatabase-each-time-or-keep-one-instance-open
  const db = await openDB<UpdateSongListDB>(test ? TestDBName : DBName, 1, {
    upgrade(db) {
      db.createObjectStore(SongListUpdateObjectStore);
    }
  });
  return db;
}

export async function recordSongListUpdate(songList: SongList, timestamp: number, {test, isDeleted}: {
  test?: boolean;
  isDeleted?: boolean;
} = {}) {
  const db = await openSingYourPartDB({test});
  await db.put(SongListUpdateObjectStore, {...songList, ...(isDeleted && {isDeleted})}, timestamp);
}

export async function getSongListUpdatesBefore(timestamp: number, {test}: {test?: boolean} = {}): Promise<DeletableSongList[]> {
  const db = await openSingYourPartDB({test});
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  let songLists = [] as SongList[];
  for (const key of keys) {
    if (key >= timestamp) {
      continue;
    }
    const songList = await db.get(SongListUpdateObjectStore, key);
    if (!songList) {
      continue;
    }
    songLists = [...songLists.filter(({id}) => id !== songList.id), songList];
  }
  return songLists;
}

export async function removeSongListUpdatesBefore(timestamp: number, {test}: {test?: boolean} = {}) {
  const db = await openSingYourPartDB({test});
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  for (const key of keys) {
    if (key >= timestamp) {
      continue;
    }
    await db.delete(SongListUpdateObjectStore, key);
  }
}

export async function songListUpdatesPending({test}: {test?: boolean} = {}): Promise<boolean> {
  const db = await openSingYourPartDB({test});
  const keys = await db.getAllKeys(SongListUpdateObjectStore);
  return keys.length > 0;
}

export async function clearAllSongListUpdates() {
  await removeSongListUpdatesBefore(Date.now() + 10000000);
}
