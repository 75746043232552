import {ensure} from "../../common/util";

export class DualAudioElement {
  private _elements: HTMLAudioElement[];
  private _current: HTMLAudioElement;
  private _isUpdating = false;
  private _pendingBlob: Blob | undefined;
  private _isIPhone = navigator.platform.toLowerCase().startsWith('iphone');

  // We put the audio element in loop mode so that it plays the next verse automatically to avoid an Apple iOS bug
  // where it fails to go to the next verse.
  // When looping to the next verse, the audio element fails to fire the 'ended' event at the end of each verse.
  // Instead, it fires a 'seeked' event at the end of each verse.
  // However, it also fires the 'seeked' event whenever the time slider is moved.
  // We can avoid triggering the next verse on time slider events by checking that the current time is non-zero,
  // *unless* the customer restarts the song from the beginning, in which case we need to ignore the next 'seeked'.
  // Note that we translated the 'seeked' event into 'ended', so the software above does not have to change.
  private _userSeekPerformed = false;

  constructor() {
    this._elements = [new Audio(), new Audio()];
    for (const [index, element] of this._elements.entries()) {
      element.loop = true;
      element.id = `audio-element-${index + 1}`;
      element.playbackRate = 1;
    }
    this._current = this._elements[0];
    this.addEventListener('canplaythrough', this._onCanPlayThrough.bind(this));
  }

  async play() {
    await this._current.play();
  }

  pause() {
    this._current.pause();
  }

  set playbackRate(rate: number) {
    for (const element of this._elements) {
      element.playbackRate = rate;
    }
  }

  get playbackRate(): number {
    return this._current.playbackRate;
  }

  get paused(): boolean {
    return this._current.paused;
  }

  get currentTime() {
    return this._current.currentTime / this._current.playbackRate;
  }

  set currentTime(value: number) {
    this._userSeekPerformed = true;
    this._current.currentTime = value;
  }

  get duration() {
    return this._current.duration / this._current.playbackRate;
  }

  set src(blob: Blob) {
    if (this._isUpdating) {
      this._pendingBlob = blob;
      return;
    }
    this._isUpdating = true;
    const prevSrc = this._other.src;
    this._other.src = (window.URL || window.webkitURL).createObjectURL(blob);
    this._other.playbackRate = 1;
    if (prevSrc) {
      (window.URL || window.webkitURL).revokeObjectURL(prevSrc);
    }
    if (this._isIPhone) {
      // iPhone audio elements do not properly call canplaythrough (iPads do)
      this._other.load(); // necessary for iPhones after setting .src
      const other = this._other;
      setTimeout(() => {
        for (const callback of this._iPhonePlaythroughCallbacks) {
          callback({target: other} as any as Event);
        }
      }, 250);
    }
  }

  readonly _iPhonePlaythroughCallbacks: ((event: Event) => void)[] = [];

  addEventListener(eventName: string, callback: (event: Event) => void) {
    if (this._isIPhone && eventName.toLowerCase() === 'canplaythrough') {
      // iPhone audio elements do not properly call canplaythrough (iPads do)
      this._iPhonePlaythroughCallbacks.push(callback);
      return;
    }
    if (eventName.toLowerCase() === 'ended') {
      // because 'element.loop' is set to true, it will not fire the 'ended' event at the end of each verse
      // so, we fire that event manually
      this._elements[0].addEventListener('seeked', this._simulateEndedEvent(callback));
      this._elements[1].addEventListener('seeked', this._simulateEndedEvent(callback));
      return;
    }
    this._elements[0].addEventListener(eventName, callback);
    this._elements[1].addEventListener(eventName, callback);
  }

  private _simulateEndedEvent(callback: (event: Event) => void): (event: Event) => void {
    return (event: Event) => {
      if (this._userSeekPerformed) {
        this._userSeekPerformed = false;
        return;
      }
      if (!this.paused) {
        callback(event);
      }
    }
  }

  private get _other() {
    return this._current === this._elements[0] ? this._elements[1] : this._elements[0];
  }

  private _onCanPlayThrough(event: Event) {
    if (!this._isUpdating) {
      return;
    }
    ensure(event.target === this._other, 'DualAudioElement: unexpected concurrent src settings');
    const current = this._current; // capture current b/c it might change
    const other = this._other; // capture current b/c it might change
    this._current = this._other;
    const synchronizeCurrentTime = () => {
      if (other.duration && current.duration) {
        const durationMultiplier = other.duration / current.duration;
        this._userSeekPerformed = true; // prevent verse advancement
        other.currentTime = current.currentTime * durationMultiplier +
          (current.paused ? 0 : this._playbackDelay());
      }
    }
    synchronizeCurrentTime();
    if (!current.paused) {
      // todo(hewitt): iOS fails to play the first time here (BUG_IOS_PLAY)
      void other.play();
      // allow the two MP3 files to overlap by 150ms to avoid an audible "gap" during the transition
      setTimeout(() => current.pause(), 150);
    }
    this._finishedUpdate();
  }

  private _playbackDelay(): number {
    // During a volume or tempo change, we regenerate a new MP3 file while the old file is still playing,
    // then we start playing the new file at the exact playback location of the old file (synchronizeCurrentTime).
    // However, iOS devices are slow to adjust the playback location for the new MP3 file.
    // It seems to be a fairly consistent of 1/4 second, regardless of CPU speed (tested on old/new devices).
    // Thus, we set the playback location of the new MP3 file to be 1/4 second *ahead* of the old MP3 file
    // so that they mesh nicely, without a skip in the playback rhythm.
    // For other devices, only a very slight delay is needed.
    // We can further refine this hack based on user feedback.
    const iPad = !!(window.navigator.userAgent.match(/(iPad)/) // @ts-ignore
      || (window.navigator.platform === "MacIntel" && typeof window.navigator.standalone !== "undefined"));
    const iPhone = !!(window.navigator.userAgent.match(/(iPhone)/));
    if (iPad || iPhone) {
      return 0.250;
    }
    return 0.075;
  }

  private _finishedUpdate() {
    this._isUpdating = false;
    if (!this._pendingBlob) {
      return;
    }
    const pendingBlob = this._pendingBlob;
    this._pendingBlob = undefined;
    this.src = pendingBlob;
  }
}
