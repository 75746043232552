import {useUserAttributes} from '../../data/use_user_attributes';
import {useEffect} from 'react';

export const useInternalPage = () => {
  const {isInternalUser} = useUserAttributes();
  useEffect(() => {
    if (!isInternalUser) {
      window.location.href = '/';
    }
  });
}