// NOTE: Node does not have a native indexeddb implementation, so we run these tests in the client at startup
//       and report failures in the console.

import * as song_updates from '../song_updates';
import {DateString} from '../../../common/date_string';
import {SongList, SongListType} from '../../../common/model';
import {clearAllSongListUpdates, DeletableSongList} from '../song_updates';

export async function runSongUpdateTests() {
  await clearTestData();
  assertDeepEqual(await getSongListUpdatesBefore(10000000), []);
  assert(!(await songListUpdatesPending()));

  const songLists = [
    songList(10, '2025-01-01'),
    songList(11, '2025-01-02'),
    songList(12, '2025-01-03', {isDeleted: true}),
  ];
  await recordSongListUpdate(songLists[0], 1);
  assert(await songListUpdatesPending());

  assertDeepEqual(await getSongListUpdatesBefore(10000000), [songLists[0]]);
  assertDeepEqual(await getSongListUpdatesBefore(2), [songLists[0]]);
  assertDeepEqual(await getSongListUpdatesBefore(1), []);

  await recordSongListUpdate(songLists[1], 2);
  assert(await songListUpdatesPending());

  assertDeepEqual(await getSongListUpdatesBefore(10000000), [songLists[0], songLists[1]]);
  assertDeepEqual(await getSongListUpdatesBefore(3), [songLists[0], songLists[1]]);
  assertDeepEqual(await getSongListUpdatesBefore(2), [songLists[0]]);
  assertDeepEqual(await getSongListUpdatesBefore(1), []);

  await recordSongListUpdate(songLists[2], 3);
  assertDeepEqual(await getSongListUpdatesBefore(4), [songLists[0], songLists[1], songLists[2]]);
  assertDeepEqual(await getSongListUpdatesBefore(1), []);

  await deleteSongListUpdatesBefore(1);
  assertDeepEqual(await getSongListUpdatesBefore(4), [songLists[0], songLists[1], songLists[2]]);

  await deleteSongListUpdatesBefore(2);
  assertDeepEqual(await getSongListUpdatesBefore(4), [songLists[1], songLists[2]]);

  await deleteSongListUpdatesBefore(3);
  assertDeepEqual(await getSongListUpdatesBefore(4), [songLists[2]]);
  assert(await songListUpdatesPending());

  await deleteSongListUpdatesBefore(4);
  assertDeepEqual(await getSongListUpdatesBefore(4), []);
  assert(!(await songListUpdatesPending()));
}

async function clearTestData() {
  await clearAllSongListUpdates();
}

function songList(id: number, date: DateString, {isDeleted}: {isDeleted?: boolean} = {}): DeletableSongList {
  return {id, date, type: SongListType.WorshipService, songs: [], ...(isDeleted && {isDeleted})};
}

function assertDeepEqual(lhs: any, rhs: any) {
  if (JSON.stringify(lhs) !== JSON.stringify(rhs)) {
    assert(false, `Expected ${JSON.stringify(lhs)} to equal ${JSON.stringify(rhs)}`);
  }
}

function assert(condition: boolean, message?: string) {
  if (!condition) {
    console.log('**********************************************************************');
    console.trace(`ERROR (song_update_test): ${message ?? 'Assertion failure'}`);
    console.log('**********************************************************************');
  }
}

function recordSongListUpdate(songList: SongList, timestamp: number) {
  return song_updates.recordSongListUpdate(songList, timestamp, {test: true});
}

function getSongListUpdatesBefore(timestamp: number) {
  return song_updates.getSongListUpdatesBefore(timestamp, {test: true});
}

function deleteSongListUpdatesBefore(timestamp: number) {
  return song_updates.removeSongListUpdatesBefore(timestamp, {test: true});
}

function songListUpdatesPending() {
  return song_updates.songListUpdatesPending({test: true});
}