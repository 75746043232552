import React, {MouseEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {clearSongListIdMap, synchronizeAllSongLists} from '../../../data/use_church';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {BackButton, BackButtonPlaceholder} from './shared'
import {
  addDayOffset,
  dateFromString,
  DateString,
  dateStringFromDate,
  DayOfWeek,
  DaysOfWeek,
  getDayOfMonth,
  getDayOfWeek,
  getMonthName,
  getMonthNameFromRawDate,
  getYear,
  thisSundayDate,
  todayDate
} from '../../../../common/date_string';
import {SongList, SongListType} from '../../../../common/model';
import {EditEventProps, EventEditor} from './event_editor';
import {AllSongLists, useAllSongLists} from './use_all_song_lists';
import {getPlatform, Platform} from '../../../util/platform';

const PastWeeks = 2 * 52;
const FutureWeeks = 52;

// only one event per day for now - would need to adjust song_lists database constraint to support multiple
const allowMultipleEventsPerDay = false;

interface Year {year: number, months: Month[]}
interface Month {name: string, year: number, dates: Array<DateString | undefined>}

export const EventCalendar = () => {
  const allSongLists = useAllSongLists();
  const navigate = useNavigate();
  const [editEventProps, setEditEventProps] = useState<EditEventProps | undefined>();
  const [containsUpdates, setContainsUpdates] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      setContainsUpdates(await allSongLists.containsUpdates());
    })();
  });

  const allYears = useMemo(() => {
    return getAllYears();
  }, []);

  useEffect(() => {
    void synchronizeAllSongLists();
    setTimeout(() => {
      const date = thisSundayDate();
      document.getElementById(getMonthElementId(getYear(date), getMonthName(date)))?.scrollIntoView({
        block: "center",
        // @ts-ignore
        behavior: "instant" });
    }, 100);
  }, []);

  const onBack = useCallback(() => {
    void (async () => {
      await synchronizeAllSongLists();
      clearSongListIdMap();
      navigate(-1);
    })();
  }, [navigate]);

  const onCloseEventEditor = useCallback(() => {
    setEditEventProps(undefined);
  }, []);

  const onOpenEventEditor = useCallback((date: DateString, songListId: number) => {
    let defaultName: string | undefined = undefined;
    const newEvent = songListId < 0;
    if (newEvent) {
      const allNamedEvents = allSongLists
        .filter(({type, name}) => type === SongListType.Event && name !== undefined)
        .sort((lhs, rhs) => lhs.date.localeCompare(rhs.date));
      const sameDayEvents = allNamedEvents.filter((list) => getDayOfWeek(list.date) === getDayOfWeek(date));
      defaultName =
        sameDayEvents.length > 0 ? sameDayEvents[sameDayEvents.length - 1].name :
          (allNamedEvents.length > 0 ? allNamedEvents[allNamedEvents.length - 1].name : undefined);
    }

    setEditEventProps({defaultName, songListId, onClose: onCloseEventEditor})
  }, [allSongLists, setEditEventProps, onCloseEventEditor]);

  function getMonthElementId(year: number, monthName: string) {
    return `event-calendar-${year}-${monthName}`;
  }

  const dateToHighlight = todayDate();

  return (
    <>
      {editEventProps && <EventEditor {...editEventProps} />}
      <OuterScrollWrapper>
        {allYears.map(year => (
          <YearWrapper key={year.year}>
            <YearHeader>
              <BackButton onClick={onBack}/>
              <BackButtonPlaceholder/>
              <YearNumber>{`${year.year} Event Calendar${containsUpdates ? ' *' : ''}`}</YearNumber>
            </YearHeader>
            {year.months.map(month => (
              <MonthWrapper key={month.name} id={getMonthElementId(year.year, month.name)}>
                <MonthHeader>
                  <MonthName>{month.name}</MonthName>
                  <WeekGrid>
                    {DaysOfWeek.map(dayOfWeek => (
                      <DayOfWeekElement key={dayOfWeek}>{dayOfWeek.slice(0, 3)}</DayOfWeekElement>
                    ))}
                  </WeekGrid>
                </MonthHeader>
                <WeekGrid>
                  {month.dates.map((date, index) => {
                    if (date === undefined) {
                      return <div key={index}/>;
                    }
                    return (
                      <Day
                        key={date}
                        allSongLists={allSongLists}
                        date={date}
                        dateToHighlight={dateToHighlight}
                        onOpenEventEditor={onOpenEventEditor}/>
                      );
                  })}
                </WeekGrid>
              </MonthWrapper>
            ))}
          </YearWrapper>
        ))}
      </OuterScrollWrapper>
    </>
  );
}

function Day({allSongLists, date, dateToHighlight, onOpenEventEditor}: {
  allSongLists: AllSongLists,
  date: DateString,
  dateToHighlight: DateString,
  onOpenEventEditor: (date: DateString, songListId: number) => void
}) {
  const songLists = allSongLists.filter(songList => songList.date === date && songList.type === SongListType.Event);

  const onOpenExistingEvent = useCallback(() => {
    const {id} = songLists[0];
    if (id) {
      setTimeout(() => onOpenEventEditor(date, id));
    }
  }, [date, onOpenEventEditor, songLists]);

  const onAddEvent = useCallback(async () => {
    const songListId = await allSongLists.add({
      date,
      type: SongListType.Event,
      songs: [],
    });
    setTimeout(() => onOpenEventEditor(date, songListId));
  }, [allSongLists, date, onOpenEventEditor]);

  const onDayClick = useCallback(() => {
    if (!allowMultipleEventsPerDay && songLists.length > 0) {
      return onOpenExistingEvent();
    }
    void onAddEvent();
  }, [onOpenExistingEvent, onAddEvent, songLists.length]);

  const onEditEvent = useCallback((songList: SongList) => (event: MouseEvent<HTMLElement>) => {
    const {id} = songList;
    if (!id) {
      console.log(`Expected ID for song list: ${JSON.stringify(songList)}`);
      return;
    }
    setTimeout(() => onOpenEventEditor(date, id));
    event.stopPropagation();
  }, [date, onOpenEventEditor]);

  const allowNewEvent = allowMultipleEventsPerDay || songLists.length === 0;

  return (
    <DayWrapper key={date} onClick={onDayClick}>
      <DayTopBorderWrapper><DayTopBorder/></DayTopBorderWrapper>
      <DayHeader>
        {allowNewEvent && <AddEventButton>New</AddEventButton>}
        <DayOfMonthWrapper $highlight={date === dateToHighlight}>
          <DayOfMonth $highlight={date === dateToHighlight}>{getDayOfMonth(date)}</DayOfMonth>
        </DayOfMonthWrapper>
      </DayHeader>
      {
        songLists
          .map(songList => (
            <Event key={songList.id} onClick={onEditEvent(songList)}>
              {songList.name ?? 'Event'}
            </Event>
          ))
      }
    </DayWrapper>
  );
}

function getAllYears(): Year[] {
  const years: Year[] = [];
  const firstDate = addDayOffset(thisSundayDate(), -(PastWeeks * 7));
  const date = dateFromString(firstDate);
  let currentYear: Year = {year: date.getFullYear(), months: []};
  let currentMonth: Month = {
    name: getMonthNameFromRawDate(date),
    year: date.getFullYear(),
    dates: []
  };
  let {dates} = currentMonth;
  for (let week = 0; week < PastWeeks + FutureWeeks; week++) {
    for (let day = 0; day < 7; day++) {
      if (getMonthNameFromRawDate(date) !== currentMonth.name) {
        // fill in blanks at end of current month
        const lastDayOfMonth = getDayOfWeek(dates[dates.length - 1] as DateString);
        for (let dayOfWeek = DayOfWeek.Saturday; dayOfWeek !== lastDayOfMonth; dayOfWeek--) {
          dates.push(undefined);
        }

        currentYear.months.push(currentMonth);
        currentMonth = {name: getMonthNameFromRawDate(date), year: date.getFullYear(), dates: []};
        if (currentYear.year !== date.getFullYear()) {
          years.push(currentYear);
          currentYear = {year: date.getFullYear(), months: []};
        }
        ({dates} = currentMonth);

        // fill in blanks at beginning of new month
        const firstDayOfMonth = date.getDay();
        for (let dayOfWeek = DayOfWeek.Sunday; dayOfWeek !== firstDayOfMonth; dayOfWeek++) {
          currentMonth.dates.push(undefined);
        }
      }
      dates.push(dateStringFromDate(date));
      date.setDate(date.getDate() + 1);
    }
  }
  currentYear.months.push(currentMonth);
  years.push(currentYear);
  return years;
}

const OuterScrollWrapper = styled.div`
  overflow: scroll;
  overscroll-behavior-x: none; /* avoid horizontal dialog scroll on iOS */
  height: 100vh;
`;

const YearWrapper = styled.div`
  isolation: isolate; // new stacking context to isolate child z-index's
  background-color: var(--color-background);
`;

// for sticky positioning
const YearHeaderHeight = '3rlh';

const YearHeader = styled.div`
  z-index: 4;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  height: ${YearHeaderHeight};
`;

const YearNumber = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.7rem;
  text-align: left;
  text-wrap: nowrap;
  overflow: hidden;
  max-width: 85vw;
`;

const MonthWrapper = styled.div`
`;

// for sticky positioning
const MonthHeaderHeight = '4rlh';

const MonthHeader = styled.div`
  z-index: 3;
  position: sticky;
  top: ${YearHeaderHeight};
  background-color: var(--color-background);
  margin-bottom: -1px;
  height: ${MonthHeaderHeight};
  overflow: hidden;
`;

const MonthName = styled.div`
  padding-left: 0.5rem;
  font-size: 2rem;
  text-align: left;
`;

const WeekGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 1px; /* avoids cell outline over-reach */
  padding: 3px; /* allows left & right cell outlines to be visible*/
`;

const AddEventButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.8rem;
  visibility: hidden;
  color: var(--color-background);
  background-color: var(--color-text);
  border-radius: 50px;
  padding: 0 10px;
  border: none;
  margin: 2px;
  cursor: pointer;
`;

const DayMinHeight = '3.5lh';

const DayWrapper = styled.div`
  position: relative;
  outline: 1px solid var(--color-text); /* outline instead of border takes up 0 space */
  display: flex;
  flex-direction: column;
  min-height: ${DayMinHeight};
  cursor: pointer;

  ${getPlatform() !== Platform.Mobile && `
    &:hover ${AddEventButton} {
      visibility: revert;
    }
    &:hover {
      z-index: 2;
      outline: 3px solid var(--color-text);
    }
  `}
`;

const DayOfWeekElement = styled.div`
  z-index: 3;
  position: sticky;
  background-color: var(--color-background);
`;

const DayTopBorderWrapper = styled.div`
  position: absolute;
  height: ${DayMinHeight};
  width: 100%;
  pointer-events: none;
;`

const DayTopBorder = styled.div`
  height: 0;
  margin-top: -1px;
  border-top: 1px solid var(--color-text);
  position: sticky;
  top: calc(${YearHeaderHeight} + ${MonthHeaderHeight});
`;

const DayHeader = styled.div`
  min-height: 1.1lh;
`;

const DayOfMonthWrapper = styled.div<{$highlight: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px 6.5px;
  ${props => !props.$highlight ? '' : `
    color: white;
    background-color: var(--color-date-highlight-background);
    border-radius: 20px;
    padding: 0 5px;
    margin: 1px;
  `}
`;

const DayOfMonth = styled.div<{$highlight: boolean}>`
  text-align: right;
  ${props => !props.$highlight ? '' : `
    min-width: 1.5ch;
    text-align: center;
  `}
`;

const Event = styled.button`
  background-color: var(--color-background-event);
  color: var(--color-text);
  border: 2px solid var(--color-text);
  border-radius: 10px;
  margin: 0 2px;
  padding: 0 8px;
  overflow: hidden;
  ${
    allowMultipleEventsPerDay ? `
      text-wrap: nowrap;
    ` : `
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      line-clamp: 2;
      -webkit-box-orient: vertical;
    `
  }
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 150%;
`;
