import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import {Hymn, SongList, SongListType, SongSlug} from '../../../../common/model';
import {HymnsList} from '../../hymns_list';
import {getPlatform, Platform} from '../../../util/platform';
import {PdfViewerPage} from '../../pdf_viewer_page';

export interface SongSelectorProps {
  initialValue?: string;
  onClose: () => void;
  onSongClick: (songSlug?: SongSlug) => void;
  row: number;
  songList: SongList;
  selectSearchValue?: boolean;
}

export const SongSelector = ({initialValue, onClose, onSongClick, row, songList, selectSearchValue}: SongSelectorProps) => {
  const dialog = useRef<HTMLDialogElement>(null);
  const [hymnToPreview, setHymnToPreview] = useState<Hymn | undefined>();

  const onClickBackground = useCallback((event: MouseEvent) => {
    if (event.target === dialog.current) {
      onClose();
    }
  }, [onClose]);


  useEffect(() => {
    const {current} = dialog;
    current?.showModal();
    current?.addEventListener('click', onClickBackground);
    return () => {current?.removeEventListener('click', onClickBackground)}
  });

  const onPreviewHymn = useCallback((hymn: Hymn | undefined) => {
    if (hymn) {
      const {issue, ...hymnNoIssue} = hymn;
      if (JSON.stringify(hymnToPreview) !== JSON.stringify(hymnNoIssue)) {
        setHymnToPreview(hymnNoIssue);
      }
    } else {
      setHymnToPreview(undefined);
    }
  }, [hymnToPreview]);

  return (
    <SongSelectorDialog ref={dialog}>
      <OuterWrapper>
        <HymnsListWrapper>
          <HymnsList
            title={'Song Selector'}
            activateSearch={true}
            onSongClick={onSongClick}
            onClose={onClose}
            defaultSearchString={initialValue}
            isInSongSelector={true}
            selectSearchValue={selectSearchValue}
            onPreviewHymn={onPreviewHymn}
            deleteButtonVisible={getPlatform() === Platform.Mobile || songList.type === SongListType.HymnOfTheMonth}
          />
        </HymnsListWrapper>
        {hymnToPreview && <PreviewPane hymn={hymnToPreview}/>}
      </OuterWrapper>
    </SongSelectorDialog>
  );
};

const songSelectorHeight = '95vh';
const songSelectorVerticalPadding = '10px';

const SongSelectorDialog = styled.dialog`
  height: ${songSelectorHeight};
  width: 95vw;
  margin: auto;
  border-radius: 15px;
  background-color: var(--color-background);
  border: none;
  overflow: hidden;
  padding: ${songSelectorVerticalPadding} 20px;
`;

const OuterWrapper = styled.div`
  display: flex;
`;

const HymnsListWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

function PreviewPane({hymn}: {hymn: Hymn}) {
  const pdfWrapperRef = useRef<HTMLDivElement>(null);
  const [pdfWidth, setPdfWidth] = useState(pdfWrapperRef.current?.clientWidth ?? 0);
  const pdfHeight = `calc(${songSelectorHeight} - (2 * ${songSelectorVerticalPadding}));`;

  // properly resize PDF on window load & resize (`clientWidth` changes don't trigger React refresh)
  const onResize = useCallback(() => {
    const clientWidth = pdfWrapperRef.current?.clientWidth;
    setPdfWidth(clientWidth ?? 0);
  }, [pdfWrapperRef]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [onResize]);

  return (
    <PreviewPaneWrapper>
      <PdfWrapper ref={pdfWrapperRef}>
        <PdfViewerPage hymn={hymn} height={pdfHeight} width={pdfWidth} onFinishedLoading={onResize}/>
      </PdfWrapper>
    </PreviewPaneWrapper>
  );
}

const PreviewPaneWrapper = styled.div`
  flex: 2;
  @media (max-width: 800px) {
    display: none;
  }
  padding-left: 20px;
`;

const PdfWrapper = styled.div`
`;
