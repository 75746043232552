import {OuterPageContent} from '../../../shared';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {songHistorySizeInWeeks, synchronizeAllSongLists, useChurch} from '../../../data/use_church';
import {
  addDayOffset,
  dateFromString,
  DateString,
  dateStringFromDate,
  daysBetween,
  getDateForMonth,
  getDayOfMonth,
  getMonthName,
  getYear,
  maxDate,
  thisSundayDate,
  todayDate,
} from '../../../../common/date_string';
import {
  OrganizationType,
  SongList,
  SongListType,
} from '../../../../common/model';
import {
  BackButton, BackButtonHeight, BackButtonPlaceholder,
  ColumnFullWidth,
  LiturgyYearHeight,
  MaxSongListRowCount,
  SongRowHeight,
  SongRowWidth
} from './shared';
import {SongCell} from './song_cell';
import {useAllSongLists} from './use_all_song_lists';
import {getNameForSunday} from '../../../../common/get_name_for_sunday';
import {MusicContext} from '../../../data/music_context';

interface DateInfo {
  date: DateString;
  column: number;
}

export interface CellPosition {
  date: string;
  row: number;
}

export const LiturgyPlanner = () => {
  const allSongLists = useAllSongLists();
  const gridRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const {hymnals, customMusic} = useContext(MusicContext);
  const {church} = useChurch();
  const isHymnOfTheMonthVisible = church?.type !== OrganizationType.School;
  const [selectedCell, setSelectedCell] = useState<CellPosition>({date: thisSundayDate(), row: 1});
  const [containsUpdates, setContainsUpdates] = useState<boolean>(false);

  useEffect(() => {
    void (async () => {
      setContainsUpdates(await allSongLists.containsUpdates());
    })();
  });

  const onBack = useCallback(() => {
    void (async () => {
      await synchronizeAllSongLists();
      navigate(-1);
    })();
  }, [navigate]);

  useEffect(() => {
    void synchronizeAllSongLists();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(getDayElementId(thisSundayDate()))?.scrollIntoView({
        block: "end",
        inline: "center",
        // @ts-ignore
        behavior: "instant",
      });
    }, 100);
  }, [gridRef]);

  const dateInfos: DateInfo[] = useMemo(() => {
    const newestDate = maxDate([...allSongLists.map(list => list.date), thisSundayDate()]);
    const oldestColumnDate = addDayOffset(thisSundayDate(), -(songHistorySizeInWeeks * 7));
    const newestColumnDate = addDayOffset(newestDate, 7);
    const weekCount = Math.round(daysBetween(oldestColumnDate, newestColumnDate) / 7) + 1;
    const dates: DateInfo[] = [];
    let date = dateFromString(oldestColumnDate);
    let currentMonth = dateFromString(oldestColumnDate).getMonth();
    let currentColumn = 1;
    for (let index = 0; index < weekCount; index++) {
      if (date.getMonth() !== currentMonth) {
        currentColumn = 1
      }
      currentMonth = date.getMonth();
      dates.push({
        date: dateStringFromDate(date),
        column: currentColumn,
      });
      date.setDate(date.getDate() + 7);
      currentColumn++;
    }
    return dates;
  }, [allSongLists]);
  const yearCount = getYear(dateInfos[dateInfos.length - 1].date) - getYear(dateInfos[0].date) + 1;
  const yearInfos = [...Array(yearCount).keys()].map(index => {
    const year = getYear(dateInfos[0].date) + index;
    const datesInYear = dateInfos.filter(({date}) => getYear(date) === year);
    const months = getUniqueMonthNames(datesInYear.map(({date}) => date)).map(monthName => ({
      monthName,
      dates: datesInYear.filter(({date}) => getMonthName(date) === monthName),
    }));
    return {year, months};
  });

  function getDateInfosForMonth(monthName: string, year: number): DateInfo[] {
    return dateInfos.filter(({date}) => getYear(date) === year && getMonthName(date) === monthName);
  }

  function getDateHeaders(monthName: string, year: number) {
    const dateToHighlight = thisSundayDate();
    return getDateInfosForMonth(monthName, year)
      .map(({date}) => (
        <DateHeader key={date} id={getDayElementId(date)} $highlight={date === dateToHighlight}>
          {titleFromDate(date)}
        </DateHeader>
      ));
  }

  function getDayElementId(date: DateString) {
    return `liturgy-day-${date}`;
  }

  const songListType = church?.type === OrganizationType.School ? SongListType.WeeklyList : SongListType.WorshipService;

  function getWeeklySongs(monthName: string, year: number) {
    const rowOffset = (isHymnOfTheMonthVisible ? 2 : 1);
    return getDateInfosForMonth(monthName, year).map(({date, column}) => {
        const songList: SongList | undefined = allSongLists.find(list =>
          list.type === songListType &&
          list.date === date
        );
        // the last month's worth of Sundays get a full stack of add song cells,
        // otherwise just a single add song cell below the last row
        const maxRow = date >= addDayOffset(todayDate(), -32) ? MaxSongListRowCount :
          ((songList ? Math.max(0, ...songList.songs.map(song => song.row)) : 0) + 1);
        const songCells = [...Array(maxRow).keys()].map(index => index + 1).map(row => {
          return (
            <SongCell
              key={`${songList?.id ?? date}-${row}`}
              column={column}
              customMusic={customMusic}
              date={date}
              gridRowOffset={rowOffset}
              hymnals={hymnals}
              row={row}
              songList={songList ?? {date, type: songListType, songs: []}}
              songListType={songListType}
              selectedCell={selectedCell}
              setSelectedCell={setSelectedCell}
              minDate={dateInfos[0].date}
              maxDate={dateInfos[dateInfos.length - 1].date}
            />
          );
        });
        return songCells;
      }
    );
  }

  function getHymnOfTheMonth(monthName: string, year: number) {
    const date = getDateForMonth(monthName, year);
    const songList = allSongLists.find(
      list => list.type === SongListType.HymnOfTheMonth && list.date === date
    );
    return isHymnOfTheMonthVisible && (
      <SongCell
        key={`${songList?.id ?? date}`}
        column={ColumnFullWidth}
        customMusic={customMusic}
        date={date}
        hymnals={hymnals}
        row={1}
        gridRowOffset={1}
        songList={songList ?? {date, type: SongListType.HymnOfTheMonth, songs: []}}
        songListType={SongListType.HymnOfTheMonth}
      />
    )
  }

  return (
    <OuterPageContent key='outer'>
      <InnerPageContent key='inner'>
        <YearStacker key='year-stacker' ref={gridRef}>{
          yearInfos.map(({year, months}) => (
            <YearWrapper key={year}>
              <YearHorizontalWrapper>
                <YearHeader key='year-header'>
                  <BackButton key='back' onClick={onBack}/>
                  <BackButtonPlaceholder/>
                  <YearNumber key='year'>{`${year} Liturgy Planner${containsUpdates ? ' *' : ''}`}</YearNumber>
                </YearHeader>
              </YearHorizontalWrapper>
              <MonthStacker key='month-stacker'>{
                months.map(({monthName, dates}) => (
                  <MonthWrapper key={monthName + year.toString()} $columnCount={dates.length}>
                    {
                      getHymnOfTheMonth(monthName, year)
                    }
                    {
                      getDateHeaders(monthName, year)
                    }
                    {
                      getWeeklySongs(monthName, year)
                    }
                  </MonthWrapper>
                ))
              }</MonthStacker>
            </YearWrapper>
          ))
        }</YearStacker>
      </InnerPageContent>
    </OuterPageContent>
  );
}

function titleFromDate(date: DateString) {
  const shortDate = `${getMonthName(date)} ${getDayOfMonth(date)}`;
  const name = getNameForSunday(date);
  if (name !== 'Ordinary Sunday') {
    return `${shortDate} - ${name}`;
  }
  return shortDate;
}

// retains order
function getUniqueMonthNames(dates: DateString[]): string[] {
  const monthNames: string[] = [];
  for (const date of dates) {
    const monthName = getMonthName(date);
    if (monthNames.length === 0 || monthNames[monthNames.length - 1] !== monthName) {
      monthNames.push(monthName);
    }
  }
  return monthNames;
}

const InnerPageContent = styled.div`
  flex: auto;
  overflow: scroll;
  overscroll-behavior-x: none; /* avoid swipe to go back */
  overscroll-behavior-y: none;
  max-width: 100vw;
  /* check out scroll-snap-points */
  /*scroll-snap-points-y: repeat(100vh);*/
  /*scroll-snap-type: y mandatory;*/
  /*scroll-snap-align: start; - on child */
`

const YearStacker = styled.div`
  display: flex;
  width: fit-content;
  text-align: left;
`;

const YearWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const YearHorizontalWrapper = styled.div`
  z-index: 4;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  height: ${BackButtonHeight};
`;

const YearHeader = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const YearNumber = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.7rem;
  max-width: 85vw;
  text-wrap: nowrap;
  overflow: hidden;
`

const MonthStacker = styled.div`
  display: flex;
  /*
      TODO(hewitt): Eliminate double borders between months.
                    See comments below about "outline" to understand how we are eliminating double borders.
                    See this post for the strategy: https://phuoc.ng/collection/css-layout/grid-without-double-borders/
  */
`;

const MonthWrapper = styled.div<{$columnCount: number}>`
  display: grid;
  grid-template-columns: repeat(${props => props.$columnCount}, ${SongRowWidth});
  grid-template-rows: repeat(${MaxSongListRowCount}, ${SongRowHeight});
  grid-gap: 1px; /* avoids cell outline over-reach */
  padding: 0 1px; /* allows left & right cell outlines to be visible*/
`;

const DateHeader = styled.div<{$highlight: boolean}>`
  z-index: 4;
  position: sticky;
  top: ${LiturgyYearHeight};
  grid-row: 1;
  font-family: Jost-SemiBold, Arial, sans-serif;
  outline: 1px solid var(--color-text); /* outline instead of border takes up 0 space */
  padding: 10px 10px;
  color: ${props => props.$highlight ? 'var(--color-date-highlight-text)' : 'var(--color-text)'};
  background-color: var(--color-background-search);
`;
