import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {EngineeringAdminWrapper} from './shared';

export const EngineeringPage = () => {
  return (
    <EngineeringAdminWrapper title={'Engineering'}>
      <LinkList>
        <LinkWrapper key={'custom-music'}><Link to={'/custom-music'}>Custom Music</Link></LinkWrapper>
        <LinkWrapper key={'broken-songs'}><Link to={'/broken-songs'}>Broken Songs</Link></LinkWrapper>
      </LinkList>
    </EngineeringAdminWrapper>
  );
}

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;

const LinkWrapper = styled.div`
  font-size: 1.3rem;
`;
