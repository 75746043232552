import React, {useCallback} from 'react';
import './song_info.css'
import './report_song_issue.css'
import './shared.css'
import {Hymn} from '../common/model';
import * as server_api from '../common/server_api';
import {ReactComponent as CloseIcon} from "./assets/close.svg";


interface Props {
  visible: boolean;
  hymn?: Hymn;
  close: () => void;
}


export const ReportSongIssue = (props: Props) => {

  const popup = document.getElementById("modal")
  const backdrop = document.getElementById("modal-backdrop")

  if (props.visible) {
    popup && popup.classList.add("active")
    backdrop && backdrop.classList.add("active")
  }

  function close() {
    popup && popup.classList.remove("active");
    backdrop && backdrop.classList.remove("active");
    props.close();
  }

  const recordIssue = useCallback(() => {
    void server_api.addIssue({
      hymnalName: props.hymn?.hymnal,
      songNumber: props.hymn?.number,
      description: {
        tag: getSelectValue('problemTag'),
        voice: getSelectValue('voice'),
        measure: getInputValue('problemMeasure'),
        description: getDescription('descriptionBox')
      },
      timestamp: Date.now(),
    });
  }, [props.hymn?.hymnal, props.hymn?.number]);


  function getSelectValue(elmId: string) {
    const elem = document.getElementById(elmId);
    if (typeof elem !== 'undefined' && elem !== null) {
      return (elem as HTMLSelectElement).value;
    } else {
      return ''
    }
  }

  function getInputValue(elmId: string) {
    const elem = document.getElementById(elmId);
    if (typeof elem !== 'undefined' && elem !== null) {
      return (elem as HTMLInputElement).value;
    } else {
      return ''
    }
  }

  function getDescription(elmId: string) {
    const elem = document.getElementById(elmId);
    if (typeof elem !== 'undefined' && elem !== null) {
      return (elem as HTMLTextAreaElement).value;
    } else {
      return ''
    }
  }


  function submit() {
    recordIssue();
    close();
  }

  return (
    <div>
      <div id="modal" style={{width: 350, height: 350}}>
        <CloseIcon id="closeIcon" onClick={close}/>
        <div style={{position: "relative", height: "95%", width: "100%", marginTop: 20}}>
          <div id="heading">
            Report an Issue
          </div>
          <div id='reportContent'>
            <div id='reportLabels'>
              <div style={{width: '33.33%', position: 'relative'}}>
                <select id='problemTag'>
                  <option disabled value={''}>Area</option>
                  <option id='option' value={'wrong note'}>Wrong Note</option>
                  <option value={'tempo'}>Tempo</option>
                  <option value={'other'}>Other</option>
                </select>
              </div>

              <div style={{width: '33.33%', position: 'relative'}}>
                <select id='voice'>
                  <option disabled value={''}>Voice</option>
                  <option value={'all'}>All</option>
                  <option value={'soprano'}>Soprano</option>
                  <option value={'alto'}>Alto</option>
                  <option value={'tenor'}>Tenor</option>
                  <option value={'bass'}>Bass</option>
                  <option value={'n/a'}>n/a</option>
                </select>
              </div>

              <div style={{width: '33.33%', position: 'relative'}}/>
            </div>

            <div style={{width: '100%', height: 45, marginLeft: 30}}>
              <div id='measureContainer'>
                <div style={{fontSize: '17px', marginLeft: '2px', lineHeight: '30px'}}>Measure:</div>
                <input type='text' id='problemMeasure'/>
              </div>
            </div>

            <div id='description'>
              <div id='descriptionLabel'>Description:</div>
              <form>
                <textarea id='descriptionBox'/>
              </form>
            </div>
            <button id='submitButton' onClick={() => submit()}>Submit</button>
          </div>
        </div>
      </div>
      <div id="modal-backdrop" onClick={() => close()}></div>
    </div>
  );
}
