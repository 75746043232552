import {
  Church,
  Favorite,
  Household,
  HouseholdStatus,
  SongListDeprecated,
  SongView,
  Subscription,
  TransposedSong,
  User,
  ConfidenceLevel,
  ExpectedUsage,
  HouseholdSurveyResponses,
  appConfigDefaultValues,
  AppConfigValues,
  SongSlug, WeeklyHouseholdSingingPlan, SongList, ClientChurch,
} from '../../common/model';
import {DateString} from '../../common/date_string';
import {ensureUnreachable} from '../../common/util';

export const Guest = 'guest';
export type UserOrGuest = User | 'guest';
export const OnboardingLocalStoragePrefix = 'onboarding/';
export type RequestSongDisplayedForPDF = {[hymnalName: string]: {[songNumber: number]: true}};

export enum LocalStorageKey {
  AllSongLists = 'all-song-lists',
  AppConfig = 'app-config',
  AppVersion = 'app-version',
  BundleHash = 'bundle-hash',
  CustomMusicHash = 'custom-music-hash',
  HymnalsHash = 'hymnals-hash',
  // TODO(halvorson): Remove 12/2024 - 'billing/household-token' replaced by 'household'
  BillingHouseholdToken = 'billing/household-token',
  Church = 'church',
  ClientSubscription = 'billing/client-subscription',
  Credentials = 'credentials',
  CurrentHymnalName = 'current-hymnal-name',
  // TODO(halvorson): Remove 12/2024 - 'billing/family-last-name' replaced by 'household'
  FamilyLastName = 'billing/family-last-name',
  Favorites = 'favorites',
  FavoritesAdded = 'favorites-added',
  FavoritesRemoved = 'favorites-removed',
  Household = 'household',
  // TODO(hewitt): Remove 9/2024 - Both 'billing/church-id' and 'billing/church-name' replaced by 'church'
  HouseholdChurchId = 'billing/church-id',
  HouseholdChurchName = 'billing/church-name',
  // TODO(hewitt): prefix with Billing*, to differentiate between billing & in-app, for migration
  // TODO(halvorson): Remove 12/2024 - both 'billing/household-email' and 'billing/household-status' replaced by 'household'
  HouseholdEmail = 'billing/household-email',
  HouseholdStatus = 'billing/household-status',
  HouseholdSingingPlan = 'household-singing-plan',
  HouseholdSingingPlanCompletedSongs = 'household-singing-plan-completed-songs',
  IOSAppVersion = 'ios-app-version',
  IsChurchAdmin = 'is-church-admin',
  IsInternalUser = 'is-internal-user',
  KeyModulation = 'key-modulation',
  MusicUploadSongName = 'music-upload-song-name',
  MusicUploadPsalmNumber = 'music-upload-psalm-number',
  OnboardingHouseholdEmail = 'onboarding/household-email',
  OnboardingLastName = 'onboarding/last-name', // a temporary cache between onboarding screens
  OnboardingChurch = 'onboarding/church',
  OnboardingConfidenceLevel = 'onboarding/confidence-level',
  OnboardingExpectedUsage = 'onboarding/expectedUsage',
  OnboardingTimeInvestment = 'onboarding/time-investment',
  OnboardingHouseholdSurveyResponses = 'onboarding/household-survey-responses',
  PdfAutoDisplay = 'pdf-auto-display',
  RequestSongDisplayedForPDF = 'request-song-displayed-for-pdf',
  PushChurch = 'pushChurch',
  PushHousehold = 'pushHousehold',
  InAppNotificationLastDisplayDate = 'in-app-notification-last-display-date',
  InAppNotificationAdventDisplayed = 'in-app-notification-advent-displayed',
  Repeat = 'repeat',
  SongViews = 'song-views',
  UpdatedSongListIds = 'updated-song-list-ids', // Remove 03/2025
  User = 'user',
  UserToken = 'user-token',
  VerseCount = 'verse-count',
  ShowVocalsByDefault = 'show-vocals-by-default',
  VoiceValues = 'voice-values',
  SongIntroduction = 'song-intro',
  ChooseChurchSkipped = 'choose-church-skipped',
  DemoSongList = 'demo-song-list',
  WeeklySongList = 'weekly-song-list',
}

export enum SongIntroduction {
  LastFiveSeconds = 'last-five-seconds',
  LastTenSeconds = 'last-ten-seconds',
  LastFifteenSeconds = 'last-fifteen-seconds',
  FullSong = 'full-song',
}

export function getSongIntroName(songIntroduction?: SongIntroduction): string {
  if (!songIntroduction) {
    return "None";
  }
  switch (songIntroduction) {
    case SongIntroduction.LastFiveSeconds:
      return "Last 5 Seconds";
    case SongIntroduction.LastTenSeconds:
      return "Last 10 Seconds";
    case SongIntroduction.LastFifteenSeconds:
      return "Last 15 Seconds";
    case SongIntroduction.FullSong:
      return "Full Song";
    default:
      ensureUnreachable(songIntroduction);
  }
}

export enum Voice {
  Soprano = 'soprano',
  Alto = 'alto',
  Tenor = 'tenor',
  Bass = 'bass',
  Piano = 'piano',
}

interface TypeMap {
  [LocalStorageKey.AllSongLists]: SongList[];
  [LocalStorageKey.AppConfig]: AppConfigValues;
  [LocalStorageKey.AppVersion]: string | undefined;
  [LocalStorageKey.BundleHash]: string | undefined;
  [LocalStorageKey.BillingHouseholdToken]: string | undefined;
  [LocalStorageKey.CustomMusicHash]: string | undefined;
  [LocalStorageKey.HymnalsHash]: string | undefined;
  [LocalStorageKey.Church]: ClientChurch | undefined;
  [LocalStorageKey.ClientSubscription]: (Subscription & {appAccountToken?: string}) | undefined;
  [LocalStorageKey.Credentials]: string | undefined;
  [LocalStorageKey.CurrentHymnalName]: string | undefined;
  [LocalStorageKey.FamilyLastName]: string | undefined;
  [LocalStorageKey.Favorites]: Favorite[];
  [LocalStorageKey.FavoritesAdded]: Favorite[];
  [LocalStorageKey.FavoritesRemoved]: Favorite[];
  [LocalStorageKey.Household]: Household | undefined;
  [LocalStorageKey.HouseholdChurchId]: number | undefined;
  [LocalStorageKey.HouseholdChurchName]: string | undefined;
  [LocalStorageKey.HouseholdEmail]: string | undefined;
  [LocalStorageKey.HouseholdStatus]: HouseholdStatus | undefined;
  [LocalStorageKey.HouseholdSingingPlan]: WeeklyHouseholdSingingPlan | undefined;
  [LocalStorageKey.HouseholdSingingPlanCompletedSongs]: {[date: DateString]: SongSlug[]};
  [LocalStorageKey.IOSAppVersion]: number | undefined;
  [LocalStorageKey.IsChurchAdmin]: boolean;
  [LocalStorageKey.IsInternalUser]: boolean;
  [LocalStorageKey.KeyModulation]: TransposedSong[];
  [LocalStorageKey.MusicUploadSongName]: string | undefined;
  [LocalStorageKey.MusicUploadPsalmNumber]: number | undefined;
  [LocalStorageKey.OnboardingHouseholdEmail]: string | undefined;
  [LocalStorageKey.OnboardingLastName]: string | undefined;
  [LocalStorageKey.OnboardingChurch]: Church | undefined;
  [LocalStorageKey.OnboardingConfidenceLevel]: ConfidenceLevel | undefined;
  [LocalStorageKey.OnboardingExpectedUsage]: ExpectedUsage[] | undefined;
  [LocalStorageKey.OnboardingTimeInvestment]: number | undefined;
  [LocalStorageKey.OnboardingHouseholdSurveyResponses]: HouseholdSurveyResponses;
  [LocalStorageKey.PdfAutoDisplay]: boolean;
  [LocalStorageKey.RequestSongDisplayedForPDF]: RequestSongDisplayedForPDF;
  [LocalStorageKey.PushChurch]: true | undefined;
  [LocalStorageKey.PushHousehold]: true | undefined;
  [LocalStorageKey.InAppNotificationLastDisplayDate]: string | undefined; // Date does not work -> only object & scalar
  [LocalStorageKey.InAppNotificationAdventDisplayed]: boolean;
  [LocalStorageKey.Repeat]: boolean;
  [LocalStorageKey.SongViews]: SongView[];
  [LocalStorageKey.UpdatedSongListIds]: number[];
  [LocalStorageKey.User]: UserOrGuest;
  [LocalStorageKey.UserToken]: string | undefined;
  [LocalStorageKey.VerseCount]: number | undefined;
  [LocalStorageKey.ShowVocalsByDefault]: boolean;
  [LocalStorageKey.VoiceValues]: VoiceValues;
  [LocalStorageKey.SongIntroduction]: SongIntroduction | undefined;
  [LocalStorageKey.ChooseChurchSkipped]: boolean | undefined;
  [LocalStorageKey.DemoSongList]: Array<SongList | SongListDeprecated>;
  [LocalStorageKey.WeeklySongList]: Array<SongList | SongListDeprecated>;
}

export const defaultVerseCount = 4; // allows defaulting to the hymn verse count

// TODO(hewitt): Freeze all objects & lists in this array (freezing the top level object does not prevent this)
const DefaultValues: {[key in LocalStorageKey]: LocalStorageType<key>} = Object.freeze({
  [LocalStorageKey.AllSongLists]: [],
  [LocalStorageKey.AppConfig]: appConfigDefaultValues,
  [LocalStorageKey.AppVersion]: undefined,
  [LocalStorageKey.BundleHash]: undefined,
  [LocalStorageKey.BillingHouseholdToken]: undefined,
  [LocalStorageKey.CustomMusicHash]: undefined,
  [LocalStorageKey.HymnalsHash]: undefined,
  [LocalStorageKey.Church]: undefined,
  [LocalStorageKey.ClientSubscription]: undefined,
  [LocalStorageKey.Credentials]: undefined,
  [LocalStorageKey.CurrentHymnalName]: undefined,
  [LocalStorageKey.FamilyLastName]: undefined,
  [LocalStorageKey.Favorites]: [],
  [LocalStorageKey.FavoritesAdded]: [],
  [LocalStorageKey.FavoritesRemoved]: [],
  [LocalStorageKey.Household]: undefined,
  [LocalStorageKey.HouseholdChurchId]: undefined,
  [LocalStorageKey.HouseholdChurchName]: undefined,
  [LocalStorageKey.HouseholdEmail]: undefined,
  [LocalStorageKey.HouseholdStatus]: undefined,
  [LocalStorageKey.HouseholdSingingPlan]: undefined,
  [LocalStorageKey.HouseholdSingingPlanCompletedSongs]: {},
  [LocalStorageKey.IOSAppVersion]: undefined,
  [LocalStorageKey.IsChurchAdmin]: false,
  [LocalStorageKey.IsInternalUser]: false,
  [LocalStorageKey.KeyModulation]: [],
  [LocalStorageKey.MusicUploadSongName]: undefined,
  [LocalStorageKey.MusicUploadPsalmNumber]: undefined,
  [LocalStorageKey.OnboardingHouseholdEmail]: undefined,
  [LocalStorageKey.OnboardingLastName]: undefined,
  [LocalStorageKey.OnboardingChurch]: undefined,
  [LocalStorageKey.OnboardingConfidenceLevel]: undefined,
  [LocalStorageKey.OnboardingExpectedUsage]: [],
  [LocalStorageKey.OnboardingTimeInvestment]: undefined,
  [LocalStorageKey.OnboardingHouseholdSurveyResponses]: {},
  [LocalStorageKey.PdfAutoDisplay]: false,
  [LocalStorageKey.RequestSongDisplayedForPDF]: {},
  [LocalStorageKey.PushChurch]: undefined,
  [LocalStorageKey.PushHousehold]: undefined,
  [LocalStorageKey.InAppNotificationLastDisplayDate]: undefined, // Date does not work -> only object & scalar
  [LocalStorageKey.InAppNotificationAdventDisplayed]: false,
  [LocalStorageKey.Repeat]: false,
  [LocalStorageKey.SongViews]: [],
  [LocalStorageKey.User]: Guest,
  [LocalStorageKey.UpdatedSongListIds]: [],
  [LocalStorageKey.UserToken]: undefined,
  [LocalStorageKey.VerseCount]: undefined,
  [LocalStorageKey.ShowVocalsByDefault]: false,
  [LocalStorageKey.VoiceValues]: {
    [Voice.Soprano]: 1,
    [Voice.Alto]: 0.5,
    [Voice.Tenor]: 0.5,
    [Voice.Bass]: 0.5,
    [Voice.Piano]: 0.5,
  },
  [LocalStorageKey.SongIntroduction]: undefined,
  [LocalStorageKey.ChooseChurchSkipped]: undefined,
  [LocalStorageKey.DemoSongList]: [],
  [LocalStorageKey.WeeklySongList]: [],
});

const nonPrefixedKeys = [
  LocalStorageKey.AllSongLists,
  LocalStorageKey.AppConfig,
  LocalStorageKey.AppVersion,
  LocalStorageKey.BundleHash,
  LocalStorageKey.BillingHouseholdToken,
  LocalStorageKey.Church,
  LocalStorageKey.ClientSubscription,
  LocalStorageKey.FamilyLastName,
  LocalStorageKey.Household,
  LocalStorageKey.HouseholdChurchId,
  LocalStorageKey.HouseholdChurchName,
  LocalStorageKey.HouseholdEmail,
  LocalStorageKey.HouseholdStatus,
  LocalStorageKey.HouseholdSingingPlan,
  LocalStorageKey.CustomMusicHash,
  LocalStorageKey.HymnalsHash,
  LocalStorageKey.IOSAppVersion,
  LocalStorageKey.PushChurch,
  LocalStorageKey.PushHousehold,
  LocalStorageKey.UpdatedSongListIds,
  LocalStorageKey.User,
  LocalStorageKey.DemoSongList,
  LocalStorageKey.WeeklySongList,
  LocalStorageKey.MusicUploadSongName,
  LocalStorageKey.MusicUploadPsalmNumber,
  LocalStorageKey.OnboardingHouseholdEmail,
  LocalStorageKey.OnboardingLastName,
  LocalStorageKey.OnboardingChurch,
  LocalStorageKey.OnboardingConfidenceLevel,
  LocalStorageKey.OnboardingExpectedUsage,
  LocalStorageKey.OnboardingTimeInvestment,
  LocalStorageKey.OnboardingHouseholdSurveyResponses,
  LocalStorageKey.SongViews,
];

export type LocalStorageType<T extends LocalStorageKey> = TypeMap[T];

export interface VoiceValues {
  [Voice.Soprano]: number;
  [Voice.Alto]: number;
  [Voice.Tenor]: number;
  [Voice.Bass]: number;
  [Voice.Piano]: number;
}

function parseJson(rawValue: string) {
  // Entries created by raw HTML pages were not originally JSON stringified... :(
  // numbers & booleans are not wrapped in quotes even when JSON stringified
  if (
      !rawValue.startsWith('{') &&
      !rawValue.startsWith('[') &&
      !rawValue.startsWith('"') &&
      !rawValue.match(/^\d/) &&
      rawValue !== 'true' &&
      rawValue !== 'false' &&
      rawValue !== 'undefined'
  ) {
    return rawValue;
  }
  return JSON.parse(rawValue);
}

export function registerForLocalStorageEvents() {
  // IMPORTANT: Not called for changes by our app -> only for changes to the cache outside the app.
  //            For example, if we manually delete a key or if two instances are running.
  window.addEventListener("storage", event => {
    if (!event.key) {
      return;
    }
    const oldValue = event.oldValue === null ? undefined : event.oldValue;
    const newValue = event.newValue === null ? undefined : event.newValue;
    keyChanged(event.key, oldValue, newValue)
  });
}

export function keyChanged(rawKey: string, oldValue?: any, newValue?: any) {
  let nonPrefixedKey: LocalStorageKey;
  if (rawKey in nonPrefixedKeys || !rawKey.includes('/')) {
    nonPrefixedKey = rawKey as LocalStorageKey;
  } else {
    nonPrefixedKey = rawKey.slice(rawKey.indexOf('/') + 1) as LocalStorageKey;
  }
  if ((oldValue === undefined && newValue === undefined) || JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
    _callListeners(nonPrefixedKey, oldValue, newValue);
  }
}

export function localStorageGet<T extends LocalStorageKey>(
  key: T,
  defaultOverride?: LocalStorageType<T>
): LocalStorageType<T> {
  upgradeLocalStorageKey(key);
  const rawValue = _localStorageGetRaw(_getPrefixedLocalStorageName(key));
  if (rawValue !== undefined) {
    return parseJson(rawValue);
  }
  return getDefaultValue(key, defaultOverride);
}

function upgradeLocalStorageKey(key: LocalStorageKey) {
  if (key === LocalStorageKey.SongIntroduction) {
    const rawValue = _localStorageGetRaw(_getPrefixedLocalStorageName(key));
    if (rawValue === 'true') {
      localStorageSet(key, SongIntroduction.LastFifteenSeconds);
    } else if (rawValue === 'false') {
      localStorageRemove(key);
    }
  }
}

export function getDefaultValue<T extends LocalStorageKey>(key: T, defaultOverride?: LocalStorageType<T>) {
  if (defaultOverride !== undefined) {
    return structuredClone(defaultOverride);
  }
  return structuredClone(DefaultValues[key]);
}

export function localStorageSet<T extends LocalStorageKey>(key: T, value: LocalStorageType<T>) {
  const oldValue = _localStorageGetRaw(_getPrefixedLocalStorageName(key)) as LocalStorageType<T>;
  if (value === undefined) {
    localStorageRemove(key);
  } else {
    _localStorageSetRaw(_getPrefixedLocalStorageName(key), JSON.stringify(value));
  }
  if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
    _callListeners(key, oldValue, value);
  }
}

export function localStorageRemove<T extends LocalStorageKey>(key: T) {
  const oldValue = _localStorageGetRaw(_getPrefixedLocalStorageName(key)) as LocalStorageType<T>;
  _localStorageRemoveRaw(_getPrefixedLocalStorageName(key));
  _callListeners(key, oldValue);
}

function _localStorageRemoveRaw(name: string) {
  localStorage.removeItem(name);
}

export function addListener<T extends LocalStorageKey>(key: T, listener: Listener) {
  _listeners[key].push(listener);
}

export function removeListener<T extends LocalStorageKey>(key: T, listener: Listener) {
  const index = _listeners[key].indexOf(listener);
  if (index !== -1) {
    _listeners[key].splice(index, 1);
  }
}

export function clearClientLocalStorage() {
  localStorage.clear();
}

// NOTE: these raw storage keys may be prefixed with user email address, so are not necessarily LocalStorageKey values
export function getAllLocalStorageKeys(): string[] {
  return Object.keys(localStorage) as string[];
}

function _localStorageGetRaw(name: string): string | undefined {
  return localStorage.getItem(name) ?? undefined;
}

function _localStorageSetRaw(name: string, value: string) {
  localStorage.setItem(name, value);
}

function _getPrefixedLocalStorageName(key: LocalStorageKey): string {
  if (nonPrefixedKeys.includes(key)) {
    return key;
  }
  const user = localStorageGet(LocalStorageKey.User);
  const prefix = user === Guest ? user : user.email;
  return `${prefix}/${key}`;
}

function _callListeners<T extends LocalStorageKey>(key: T, _oldValue?: LocalStorageType<T>, _newValue?: LocalStorageType<T>) {
  for (const listener of _listeners[key]) {
    listener();
  }
}

// todo(hewitt): would be nice someday, but gathering up all old values when `user` changes is challenging
// export type ListenerArgs<T extends LocalStorageKey> = {
//   oldValue: LocalStorageType<T> | undefined;
//   newValue: LocalStorageype<T> | undefined;
// };
// export type Listener<T extends StorageKey> = (args: ListenerArgs<T>) => void;

export type Listener = () => void;

const _listeners: {[key in LocalStorageKey]: Listener[]} = {
  [LocalStorageKey.AllSongLists]: [],
  [LocalStorageKey.AppConfig]: [],
  [LocalStorageKey.AppVersion]: [],
  [LocalStorageKey.BundleHash]: [],
  [LocalStorageKey.BillingHouseholdToken]: [],
  [LocalStorageKey.CustomMusicHash]: [],
  [LocalStorageKey.HymnalsHash]: [],
  [LocalStorageKey.Church]: [],
  [LocalStorageKey.ClientSubscription]: [],
  [LocalStorageKey.Credentials]: [],
  [LocalStorageKey.CurrentHymnalName]: [],
  [LocalStorageKey.FamilyLastName]: [],
  [LocalStorageKey.Favorites]: [],
  [LocalStorageKey.FavoritesAdded]: [],
  [LocalStorageKey.FavoritesRemoved]: [],
  [LocalStorageKey.Household]: [],
  [LocalStorageKey.HouseholdChurchId]: [],
  [LocalStorageKey.HouseholdChurchName]: [],
  [LocalStorageKey.HouseholdEmail]: [],
  [LocalStorageKey.HouseholdStatus]: [],
  [LocalStorageKey.HouseholdSingingPlan]: [],
  [LocalStorageKey.HouseholdSingingPlanCompletedSongs]: [],
  [LocalStorageKey.IsChurchAdmin]: [],
  [LocalStorageKey.IsInternalUser]: [],
  [LocalStorageKey.IOSAppVersion]: [],
  [LocalStorageKey.KeyModulation]: [],
  [LocalStorageKey.MusicUploadSongName]: [],
  [LocalStorageKey.MusicUploadPsalmNumber]: [],
  [LocalStorageKey.OnboardingHouseholdEmail]: [],
  [LocalStorageKey.OnboardingLastName]: [],
  [LocalStorageKey.OnboardingChurch]: [],
  [LocalStorageKey.OnboardingConfidenceLevel]: [],
  [LocalStorageKey.OnboardingExpectedUsage]: [],
  [LocalStorageKey.OnboardingTimeInvestment]: [],
  [LocalStorageKey.OnboardingHouseholdSurveyResponses]: [],
  [LocalStorageKey.RequestSongDisplayedForPDF]: [],
  [LocalStorageKey.PdfAutoDisplay]: [],
  [LocalStorageKey.PushChurch]: [],
  [LocalStorageKey.PushHousehold]: [],
  [LocalStorageKey.InAppNotificationLastDisplayDate]: [],
  [LocalStorageKey.InAppNotificationAdventDisplayed]: [],
  [LocalStorageKey.Repeat]: [],
  [LocalStorageKey.SongViews]: [],
  [LocalStorageKey.UpdatedSongListIds]: [],
  [LocalStorageKey.User]: [],
  [LocalStorageKey.UserToken]: [],
  [LocalStorageKey.VerseCount]: [],
  [LocalStorageKey.ShowVocalsByDefault]: [],
  [LocalStorageKey.VoiceValues]: [],
  [LocalStorageKey.SongIntroduction]: [],
  [LocalStorageKey.ChooseChurchSkipped]: [],
  [LocalStorageKey.DemoSongList]: [],
  [LocalStorageKey.WeeklySongList]: [],
};
