import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {EngineeringAdminWrapper} from '../shared';
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro';
import {generateHymnUrl, getPdfUrlForHymn} from '../../../../util/path';
import {Hymn} from '../../../../../common/model';
import {Spinner} from '../../../../util/spinner';
import {useChurches} from '../../../../data/use_churches';
import {MusicContext} from '../../../../data/music_context';

export const CustomMusicPage = () => {
  const {customMusic} = useContext(MusicContext);
  const [isMissingMusicXmlFile, setIsMissingMusicXmlFile] = useState<{[songId: string]: boolean}>({});
  const churches = useChurches();

  useEffect(() => {
    if (!customMusic) {
      return;
    }
    void (async () => {
      const allMusicXMLDownloadResults = await Promise.all(
        Object.entries(customMusic).map(([orgId, hymns]) =>
          hymns.map(async (hymn) => {
            const response = await fetch(getPdfUrlForHymn(hymn).replace('.pdf', '.xml'));
            return [getSongId(orgId, hymn), !response.ok || response.headers.get('Content-Type') !== 'application/xml'];
          })
        ).flat(1)
      );
      setIsMissingMusicXmlFile(Object.fromEntries(allMusicXMLDownloadResults));
    })();
  }, [customMusic]);

  // TODO(hewitt): support music XML file upload
  const onClickUploadMusicXMLFile = useCallback(() => {}, []);

  return (
    <EngineeringAdminWrapper title={'Custom Music'}>
      <OuterWrapper>
        <UploadButton onClick={onClickUploadMusicXMLFile}>Upload Music XML</UploadButton>
        <Content key='content'>
          Songs with a <PDFLink $visible={true}>PDF link</PDFLink> do not yet have MusicXML files:
        </Content>
        {
          Object.keys(isMissingMusicXmlFile).length === 0
          ? <Spinner/>
          : <SongsToUpload key='songs-to-upload'>
            {
              // TODO(hewitt): Add third column with PDF download link
              // TODO(hewitt): Strikethrough font for test churches
              // TODO(hewitt): Red background for songs to upload
              !customMusic ? null :
                Object.entries(customMusic).map(([orgId, hymns]) =>
                  hymns
                    .map((hymn) => {
                      const songId = getSongId(orgId, hymn);
                      return (
                        <Fragment key={songId}>
                          <div key='org-id'>{churches.find(church => church.id === Number(orgId))?.name}</div>
                          <Link key='hymn-url' to={generateHymnUrl({hymn, suppressSongIssue: true})}>
                            {hymn.title}
                          </Link>
                          <PDFLink
                            key='pdf'
                            href={getPdfUrlForHymn(hymn)}
                            download={`${orgId}_${hymn.title}`}
                            $visible={isMissingMusicXmlFile[songId]}
                          >
                            PDF
                          </PDFLink>
                        </Fragment>
                      );
                    }))
            }
          </SongsToUpload>
        }
      </OuterWrapper>
    </EngineeringAdminWrapper>
  );
}

function getSongId(orgId: string, hymn: Hymn) {
  return `${orgId}-${hymn.number}`;
}

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 20px;
`;

const SongsToUpload = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 10ch;
  justify-items: left;
`;

const UploadButton = styled.button`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3rem;
  color: var(--color-text);
  padding: 0 15px;
  margin: 10px 0;
  border: 2px solid var(--color-text);
  border-radius: 10px;
  background-color: var(--color-background);
  cursor: pointer;
`;

const Content = styled.div`
  margin: 20px 0;
`;

const PDFLink = styled.a<{$visible: boolean}>`
  color: var(--color-pdf-upload);
  ${props => props.$visible ? '' : 'visibility: hidden;'}
`;
