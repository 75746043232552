import {Hymn, HymnIssue} from "../common/model";
import {isInsideAppleAppStoreApp} from './util/billing';
import styled from 'styled-components/macro';

export const churchHymnalIconPath = '/images/open-book.svg';
export const favoritesHymnalIconPath = '/images/bookmark.png';

export function getHymnFromUrl(search?: string): Hymn[] | undefined{
  if (!search) {
    return;
  }
  const searchParams = new URLSearchParams(search);
  const hymnTitle = searchParams.get('title');
  const hymnNumber = Number(searchParams.get('number'));
  const hymnalName = searchParams.get('hymnal');
  const basePath = searchParams.get('basePath') as (string | undefined);
  const issue = searchParams.get('issue') as (HymnIssue | undefined);

  if(!hymnTitle || !hymnNumber || !hymnalName) {
    return;
  }
  let hymn: Hymn;

  const correctedHymnTitle = hymnTitle.replace(/_/g, ' ');
  const correctedHymnalName = hymnalName.replace(/_/g, ' ');
  const psalmName = searchParams.get('psalm');

  if (psalmName) {
    const correctedPsalmName = psalmName.replace(/_/g, ' ');

    hymn = {
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      psalm: correctedPsalmName,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
    };
  } else {
    hymn = {
      hymnal: correctedHymnalName,
      title: correctedHymnTitle,
      number: hymnNumber,
      ...(basePath && {basePath}),
      ...(issue && {issue}),
    };
  }

  return [hymn];
}

export const OuterPageContent = styled.div<{$noHeight?: boolean}>`
  display: flex;
  flex-flow: column;
  // Account for mobile nav bar height (iOS Safari fails to access the CSS variable, so short circuit)
  height: ${props =>
    props.$noHeight ? 'auto' : (
      isInsideAppleAppStoreApp() ? '100vh' : 'var(--view-height, 100vh)'
    )
};
`

export const InnerPageContent = styled.div`
  flex: auto;
  overflow-y: auto;
  overflow-x: clip;
  white-space: nowrap;
  max-width: 100vw;
`

export const SettingsTransitionWrapper = styled(OuterPageContent)<{$left?: string;}>`
  position: fixed;
  left: ${props => props.$left};
  width: 100%;
  top: 0;
  bottom: 0;
  transition: left 200ms;
  transition-timing-function: ease-out;
`;

export const PlainLinkOutlined = styled.a`
  margin-top: 10px;
  padding: 5px 10px;
  border: 3px solid var(--color-text);
  border-radius: 15px;
  cursor: pointer;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.3em;
  text-decoration: none;
  width: fit-content;

  &:hover {
    background-color: lightgray;
  }
`;

export const ContactSupportLink = ({capitalized}: {capitalized?: boolean}) => (
  <a href="mailto:support@crescendosw.com">{capitalized ? 'C' : 'c'}ontact support</a>
);

export const FloatingBackButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 36px;
  z-index:1000;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 2px 8px;
  padding: 15px 10px;
  border-radius: 3px;
  cursor: pointer;
`
