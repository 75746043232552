import React from 'react';
import './verse_bar.css'



export interface Props {
  intro?: boolean;
  verseCount: number;
  selectedVerse: number;
  onClick: (verse: number) => void;
}

export const VerseBar = ({intro, verseCount, selectedVerse, onClick}: Props) => {
  function renderVerses() {
    return [...Array(verseCount + (intro ? 1 : 0)).keys()].map(verseIndex => {
      let name: string;
      if (intro && verseIndex === 0) {
        name = 'Int';
      } else {
        name = (intro ? verseIndex : verseIndex + 1).toString();
      }
      return (
        <div
          key={verseIndex}
          className='verse'
          id={name}
          style={verseIndex === selectedVerse ? {backgroundColor: '#e9e9eb'} : {backgroundColor: ''}}
          onClick={() => onClick(verseIndex)}
        >
          {name}
        </div>
      );
    });
  }

  return (
    <div id='verseContainer'>
      {renderVerses()}
    </div>
  )
}