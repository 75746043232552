import {useEffect} from "react";
import * as server_api from "../../common/server_api";
import {Guest, localStorageGet, LocalStorageKey, localStorageSet} from "./client_local_storage";
import {useLocalStorage} from './use_local_storage';
import {useHousehold} from './use_household';
import {isAdminForCurrentChurch} from '../../common/model';
import {useChurch} from './use_church';

export function useUserAttributes(): {
  isInternalUser: boolean;
  isChurchAdmin: boolean;
} {
  const [user] = useLocalStorage(LocalStorageKey.User);
  const [isInternalUser, setIsInternalUser] = useLocalStorage(LocalStorageKey.IsInternalUser);
  const [isChurchAdmin, setIsChurchAdmin] = useLocalStorage(LocalStorageKey.IsChurchAdmin);
  const {household} = useHousehold();
  const {church} = useChurch();

  useEffect(() => {
    if (!user) {
      setIsInternalUser(false);
      return;
    }
    void (async () => {
      try {
        const isInternalUserLocal = await server_api.getIsInternalUser();
        setIsInternalUser(isInternalUserLocal);
        localStorageSet(LocalStorageKey.IsInternalUser, isInternalUserLocal);
      } catch {}
    })();
  }, [user, setIsInternalUser]);

  useEffect(() => {
    if (household && isAdminForCurrentChurch(household)) {
      setIsChurchAdmin(true);
      return;
    }
    if (!church) {
      setIsChurchAdmin(false);
      return;
    }
    void (async () => {
      setIsChurchAdmin(await server_api.isUserAdminForChurch(church.id));
    })();
  }, [church, household, setIsChurchAdmin]);

  return {isInternalUser, isChurchAdmin};
}

export async function recordUserHousehold() {
  const user = localStorageGet(LocalStorageKey.User);
  const household = localStorageGet(LocalStorageKey.Household);
  if (!user || !household?.token || user === Guest) {
    return;
  }
  try {
    await server_api.setUserHousehold({householdToken: household.token});
  } catch (error: any) {
    console.log(`Failed to set household for user ${JSON.stringify(user)}: ${error}`);
  }
}
