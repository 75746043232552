import {ensureExists} from '../../common/util';
import {millisecondsPerDay} from '../../common/date_string';

// counts total time that at least one subscriber is active
export class Stopwatch {
  private _accumulatedMs = 0;
  private _startTime: number | undefined;
  private _activeSubscribers = new Set<number>();
  private _nextSubscriber = 1; // truthy

  subscribe(): number {
    const subscriber = this._nextSubscriber;
    this._nextSubscriber += 1;
    if (this._activeSubscribers.size === 0) {
      this._startTime = Date.now();
    }
    this._activeSubscribers.add(subscriber);
    return subscriber;
  }

  unsubscribe(subscriber: number) {
    if (!this._activeSubscribers.has(subscriber)) {
      throw new Error(`Subscriber not active: ${subscriber}`);
    }
    this._activeSubscribers.delete(subscriber);
    if (this._activeSubscribers.size === 0) {
      this._accumulatedMs += Date.now() - ensureExists(this._startTime);
      this._startTime = undefined;
    }
  }

  get totalTimeMs(): number {
    const additionalMs = this._startTime ? Date.now() - this._startTime : 0;
    return this._accumulatedMs + additionalMs;
  }

  clear() {
    this._accumulatedMs = 0;
    if (this._startTime) {
      this._startTime = Date.now();
    }
  }
}

export function tomorrowsDate(): Date {
  return new Date(new Date().setHours(0, 0, 0, 0) + millisecondsPerDay);
}

export function nextSundaysDate(): Date {
  const daysUntilSunday = 7 - new Date().getDay();
  return new Date(new Date().setHours(0, 0, 0, 0) + daysUntilSunday * millisecondsPerDay);
}
