import {useLocalStorage} from '../../../data/use_local_storage';
import {LocalStorageKey} from '../../../data/client_local_storage';
import {useCallback, useState} from 'react';
import {SongList, SongListTypeSupportsDeletion} from '../../../../common/model';
import {synchronizeAllSongLists} from '../../../data/use_church';
import {recordSongListUpdate, songListUpdatesPending} from '../../../data/song_updates';

export interface AllSongLists {
  add(songList: SongList): Promise<number>;
  containsUpdates(): Promise<boolean>;
  find(callback: (songList: SongList) => boolean): SongList | undefined;
  filter(callback: (songList: SongList) => boolean): SongList[];
  map<T>(callback: (songList: SongList) => T): T[];
  remove(songList: SongList): Promise<void>;
  update(songList: SongList): Promise<void>;
}

export function useAllSongLists(): AllSongLists {
  const [allSongLists, setAllSongLists] = useLocalStorage(LocalStorageKey.AllSongLists);
  const [nextSongListId, setNextSongListId] = useState(-1);

  const markSongListUpdated = useCallback(async (songList: SongList, {isDeleted}: {isDeleted?: boolean} = {}) => {
    await recordSongListUpdate(songList, Date.now(), {isDeleted});
    void synchronizeAllSongLists();
  }, []);

  const allocateNewSongListId = useCallback((): number => {
    const result = nextSongListId;
    setNextSongListId(result - 1);
    return result;
  }, [nextSongListId]);

  const containsUpdates = useCallback(async () => {
    return await songListUpdatesPending();
  }, []);

  const find = useCallback((callback: (songList: SongList) => boolean) => {
    return allSongLists.find(callback);
  }, [allSongLists]);

  const filter = useCallback((callback: (songList: SongList) => boolean) => {
    return allSongLists.filter(callback);
  }, [allSongLists]);

  const map = useCallback((callback: (songList: SongList) => any) => {
    return allSongLists.map(callback);
  }, [allSongLists]);

  const add = useCallback(async (songList: SongList) => {
    if (songList.id) {
      throw new Error(`Unexpected id found on new song list: ${JSON.stringify(songList)}`);
    }
    const id = allocateNewSongListId();
    setAllSongLists([...allSongLists, {...songList, id}]);
    await markSongListUpdated({...songList, id});
    return id;
  }, [allSongLists, allocateNewSongListId, markSongListUpdated, setAllSongLists]);

  const remove = useCallback(async (songList: SongList) => {
    if (songList?.type !== SongListTypeSupportsDeletion) { // only allow events to be removed
      return;
    }
    setAllSongLists(allSongLists.filter(current => current.id !== songList.id));
    await markSongListUpdated(songList, {isDeleted: true});
  }, [allSongLists, setAllSongLists, markSongListUpdated]);

  const update = useCallback(async (songList: SongList) => {
    const {id} = songList;
    if (!id) {
      throw new Error(`ERROR: id required when updating song list`);
    }
    setAllSongLists([...allSongLists.filter(list => list.id !== id), songList]);
    await markSongListUpdated(songList);
  }, [allSongLists, markSongListUpdated, setAllSongLists]);

  return {add, containsUpdates, find, filter, map, remove, update};
}
