import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAllowInAppNotifications} from '../../util/use_allow_in_app_notifications';
import {useLocalStorage} from '../../data/use_local_storage';
import {LocalStorageKey} from '../../data/client_local_storage';
import {Message, navigateToOnboardingPage} from './common';
import {OnboardingPages, Pages} from '../../../common/pages';
import {Frame} from './frame';
import {Button} from './button';
import styled from 'styled-components/macro';
import {getUrlForPage} from '../../util/path';
import {createDateString, todayDate} from '../../../common/date_string';

const UntilDate = createDateString(2024, 12, 25);

export function useDisplayInAppAdventNotification() {
  const allowInAppNotifications = useAllowInAppNotifications();
  const [adventDisplayed] = useLocalStorage(LocalStorageKey.InAppNotificationAdventDisplayed);
  const navigate = useNavigate();

  useEffect(() => {
    if (allowInAppNotifications && !adventDisplayed && todayDate() <= UntilDate) {
      navigateToOnboardingPage(navigate, OnboardingPages.InAppAdventNotification)
    }
  }, [allowInAppNotifications, adventDisplayed, navigate]);
}

export function InAppAdventNotification() {
  const [, setAdventDisplayed] = useLocalStorage(LocalStorageKey.InAppNotificationAdventDisplayed);
  const navigate = useNavigate();
  const onDismiss = useCallback(() => {
    setAdventDisplayed(true);
    // wait for new setting to take effect before navigating (setTimeout)
    // Note that navigate(-1) would be better, but doesn't work if this is the first page.
    // Some posts suggest checking navigate.key to see if there is a previous page, but that doesn't seem to work.
    setTimeout(() => navigate(getUrlForPage(Pages.Today)));
  }, [navigate, setAdventDisplayed]);

  return (
    <Frame
      onboardingPage={OnboardingPages.InAppAdventNotification}
      mainContent={(
        <>
          <Candle src='/images/candle.png'/>
          <Title>Welcome to Advent!</Title>
          <Announcement $widthInChars={24}>
            We’ve launched our first version of “Singing Plans” so we can get ready for Sunday and for Christmas together every day.
          </Announcement>
        </>
      )}
      footerContent={(
        <>
          <Button onClick={onDismiss}>DISMISS</Button>
        </>
      )}
      onEnterKeypress={onDismiss}
      suppressBackButton={true}
    />
  );
}

const Title = styled.div`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1.5em;
  padding-top: 15px;
  padding-bottom: 30px;
`;

const Announcement = styled(Message)`
  text-align: center;
  font-size: 1.1em;
  padding-top: 15px;
  padding-bottom: 25px;
`;

const Candle = styled.img`
  margin-left: -10px;
  height: 150px; // 200px better on larger screens...
`;
