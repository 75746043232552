import {Tile} from './shared';
import {OrganizationPage} from '../organization_page';
import {SongListType} from '../../../common/model';
import {DateString, thisSundayDate} from '../../../common/date_string';
import {useCallback, useState} from 'react';

export const ThisSundayTile = () => {
  const [hidden, setHidden] = useState(true);
  const checkSongLists = useCallback((songLists: Array<{type: SongListType, date: DateString}> | undefined) => {
    setHidden(songLists?.find(songList =>
      songList.type === SongListType.WorshipService && songList.date >= thisSundayDate()) === undefined
    );
  }, []);
  return (
    <Tile $thinTopPadding={true} $hidden={hidden}>
      <OrganizationPage showThisSundayOnly={true} checkSongLists={checkSongLists}/>
    </Tile>
  );
}
