import * as server_api from '../../common/server_api';
import React, {useCallback, useEffect, useState} from 'react';
import {synchronizeHouseholdWithServer, useHousehold} from '../data/use_household';
import {OnboardingPages} from '../../common/pages';
import {
  ChurchAdminTokenQueryString,
  ChurchIdTokenQueryString, clearOnboardingLocalStorage,
  Message, navigateAfterInvite,
  navigateToOnboardingPage
} from './onboarding/common';
import {Frame} from './onboarding/frame';
import {Button} from './onboarding/button';
import styled from 'styled-components/macro';
import {synchronizeChurchWithServer, useChurch} from '../data/use_church';
import {useNavigate} from 'react-router-dom';

export const AdminInvite = () => {
  const {household} = useHousehold();
  const householdToken = household?.token;
  const {church} = useChurch();
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState('');
  const urlParams = new URLSearchParams(window.location.search);
  const churchId = urlParams.get(ChurchIdTokenQueryString);
  const churchAdminToken = urlParams.get(ChurchAdminTokenQueryString);
  const navigate = useNavigate();

  useEffect(() => {
    if (churchId && churchAdminToken && householdToken && !finished && !error) {
      (async () => {
        try {
          // skipping this step for now - will retroactively grant admin privileges in the future when Coda is nixed
          // and we have household members -> really, a member should be an admin, not the household itself
          // await server_api.grantChurchAdmin({churchId: Number(churchId), churchAdminToken, userToken});
          // await server_api.setUserHousehold({householdToken});
          await server_api.setHouseholdChurch(householdToken, {id: Number(churchId)});
          await server_api.setHouseholdAdmin(householdToken, {isAdminForChurchId: Number(churchId)});
          await synchronizeChurchWithServer();
          await synchronizeHouseholdWithServer();
          clearOnboardingLocalStorage();
          setFinished(true);
        } catch (e: any) {
          setError(e.message);
        }
      })();
    }
  }, [churchId, churchAdminToken, householdToken, finished, error]);

  const onContinue = useCallback(() => {
    navigateAfterInvite(navigate, household);
  }, [household, navigate]);

  useEffect(() => {
    if (!householdToken) {
      navigateToOnboardingPage(navigate, OnboardingPages.HouseholdFind);
    }
  }, [householdToken, navigate]);

  if (!churchId || !churchAdminToken) {
    const href = 'mailto:support@crescendosw.com?subject=Missing church tokens for admin invite';
    return (
      <Frame
        onboardingPage={undefined}
        mainContent={(
          <Message $widthInChars={20}>
            An internal error has occurred. <MailLink href={href}>Click here</MailLink> to reach out to support.
          </Message>
        )}
        footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
        onEnterKeypress={onContinue}
      />
    );
  }

  // useful for debugging
  // return (
  //   <div>
  //     <div>Household Token: {householdToken}</div>
  //     <div>User Token: {userToken}</div>
  //     <div>Church ID: {churchId}</div>
  //     <div>Church Admin Token: {churchAdminToken}</div>
  //   </div>
  // );

  if (error) {
    return (
      <Frame
        onboardingPage={undefined}
        mainContent={<Message $widthInChars={22}>{error}</Message>}
        footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
        onEnterKeypress={onContinue}
      />
    );
  }

  if (!finished || !church) {
    return (
      <Frame
        onboardingPage={undefined}
        mainContent={<Message $widthInChars={24}>Setting up administrator account...</Message>}
        footerContent={<div/>}
      />
    );
  }

  return (
    <Frame
      onboardingPage={undefined}
      mainContent={(
        <Message $widthInChars={24}>
          Congratulations!<br/>
          <br/>
          As an administrator of {
            (church.name).length <= 26 ? (<span><br/>{church.name},<br/></span>) : church.name + ','
          } your household has been granted full access to the hymnal library.
        </Message>
      )}
      footerContent={<Button onClick={onContinue}>CONTINUE</Button>}
      onEnterKeypress={onContinue}
      suppressBackButton={true}
    />
  );
}

const MailLink = styled.a`
  color: blue;
  text-decoration: underline;
`;
