import {Church} from '../../common/model';
import {useEffect, useState} from 'react';
import * as server_api from '../../common/server_api';
import {stringFromChurch} from '../../common/server_api';
import {DemoChurch} from './demo_song_list';

// TODO(hewitt): Eliminate this unnecessary church entry
const testChurchName = 'Test Church';

export function useChurches(): Church[] {
  const [churches, setChurches] = useState<Church[]>([]);

  useEffect(() => {
    (async () => {
      setChurches(
        (await server_api.getChurches()).filter(church =>
          church.name !== testChurchName && stringFromChurch(church) !== DemoChurch
        )
      )
    })();
  }, []);

  return churches;
}
