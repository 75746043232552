export enum Environment {
  Production = 'production',
  Test = 'test',
  Development = 'development',
}

// works on client or server
export function getEnvironment(): Environment {
  if (inBrowser()) {
    if (window.location.hostname === "singyourpart.app") {
      return Environment.Production;
    }
    if (window.location.hostname === "test.singyourpart.app") {
      return Environment.Test;
    }
    return Environment.Development;
  }
  if (process.env.NODE_ENV === 'production') {
    return Environment.Production;
  }
  if (process.env.NODE_ENV === 'test') {
    return Environment.Test;
  }
  return Environment.Development;
}

export function inBrowser(): boolean {
  return typeof window !== 'undefined';
}
