import * as server_api from '../../common/server_api';
import {localStorageGet, LocalStorageKey, localStorageSet} from './client_local_storage';
import {
  AppConfig,
  appConfigDefaultValues,
  AppConfigType,
  AppConfigValues,
  PartialAppConfigValues
} from '../../common/model';
import {useLocalStorage} from './use_local_storage';

const ConfigSyncIntervalMinutes = 5;

export async function synchronizeAppConfigWithServer() {
  // ignore network errors (e.g. when offline)
  try {
    const serverAppConfig = await server_api.getAppConfig();
    const appConfig: PartialAppConfigValues = {};
    for (const key of Object.values(AppConfig)) {
      appConfig[key] = key in serverAppConfig
        ? processAppConfigValue(serverAppConfig[key])
        : appConfigDefaultValues[key];
    }
    localStorageSet(LocalStorageKey.AppConfig, appConfig as AppConfigValues);
  } catch (error) {
    console.log(`Failed to synchronize app config: ${error}`);
  }
  setTimeout(synchronizeAppConfigWithServer, ConfigSyncIntervalMinutes * 60 * 1000);
}

// converts a vanilla app config object into a boolean
// type checking is very loose, but we cannot enforce types based on runtime values (key)
function processAppConfigValue(value?: any): any {
  if (value === undefined || value === null) {
    return false;
  }
  if (typeof value === 'boolean' || typeof value === 'number') {
    return value;
  }
  if ('internal' in value) {
    return localStorageGet(LocalStorageKey.IsInternalUser);
  }
  // checking the environment is nonsense because we would need to manually set the value in each environment
  // if ('environment' in value) {
  //   const {environment}: {environment: Environment} = value;
  //   if (environment === Environment.Production && getEnvironment() === Environment.Production) {
  //     return true;
  //   }
  //   if (environment === Environment.Test && getEnvironment() !== Environment.Production) {
  //     return true;
  //   }
  //   return true;
  // }
  return false;
}

export function useAppConfig<T extends AppConfig>(config: T): AppConfigType<T> {
  const [appConfig] = useLocalStorage(LocalStorageKey.AppConfig);
  return appConfig[config];
}

export function getAppConfig<T extends AppConfig>(config: T): AppConfigType<T> {
  const appConfig = localStorageGet(LocalStorageKey.AppConfig);
  return appConfig[config];
}
